import { useContext, useEffect } from 'react'
import { NetworkStatus, useQuery } from '@apollo/client'
import {
  CursorDirection,
  LawyerLegalMattersListingDocument,
  LegalMatterStatus,
} from '../../../@types/generated/graphql'
import { useNavigate, useLocation } from 'react-router-dom'
import Alert from '@mui/material/Alert'
import Divider from '@mui/material/Divider'
import Typography from '@mui/material/Typography'
import LegalMatterListItem from 'view/components/organisms/LegalMatterListItem'
import { LegalMatterListLoading } from '../molecules/LoadingSkeletons'
import FiltersLegalMatters from 'view/components/molecules/FiltersLegalMatters'
import FirmsDropdown from '../organisms/FirmsDropDown'
import { UserProfileContext } from 'view/providers/UserProfileProvider'
import ConfirmationAlert from 'view/components/atoms/ConfirmationAlert'
import ErrorScreen from 'view/components/organisms/ErrorScreen'
import { AlertContent } from '../../../@types/DialogControlType'
import { EventProviderContext } from 'view/providers/EventProvider'
import { useNotificationHandler } from 'utils/useNotificationHandler'
import { paginationLimits } from 'config'
import LoadingButton from '@mui/lab/LoadingButton'
import Box from '@mui/material/Box'
import Access from '../molecules/Access'
import Grid from '@mui/material/Grid'
import { useLegalMatterFilters } from '../../../utils/useLegalMatterFilters'
import {
  LawyerLegalMatterStatusLabels,
  LegalMatterFilterType,
} from 'utils/StatusMapper'
import { routes } from 'routes'

const LawyerLegalMatterListing = (): JSX.Element => {
  const componentIdentifier = LawyerLegalMatterListing.name
  const { user } = useContext(UserProfileContext)
  const { registerEvents, unregisterEvents } = useContext(EventProviderContext)
  const { enqueueNotification } = useNotificationHandler()
  const navigate = useNavigate()
  const location = useLocation()

  const {
    assignedLawyerRef,
    firmRef,
    filters,
    filterName,
    statusMapper,
    handleStatusChange,
    handleFirmChange,
  } = useLegalMatterFilters(
    LegalMatterFilterType.LAWYER_USER,
    user?.roles,
    user?.key,
  )

  const displayAlert =
    location.state === 'REJECTED' ||
    location.state === LegalMatterStatus.Withdrawn

  const { data, networkStatus, error, fetchMore } = useQuery(
    LawyerLegalMattersListingDocument,
    {
      variables: {
        ...filters,
        assignedLawyerRef,
        firmRef: firmRef,
        limit: paginationLimits.legalMatters,
        cursor: null,
        cursorDirection: CursorDirection.Next,
        calendarEventsLimit: paginationLimits.calendarEvents,
        calendarEventsCursor: null,
        calendarEventsCursorDirection: CursorDirection.Next,
      },
      notifyOnNetworkStatusChange: true,
    },
  )

  useEffect(() => {
    return () => {
      unregisterEvents(componentIdentifier)
    }
  }, [unregisterEvents, componentIdentifier])

  useEffect(() => {
    registerEvents(
      componentIdentifier,
      [
        'LegalMatterCreated',
        'LegalMatterCancelled',
        'LegalMatterAccepted',
        'LegalMatterAssigned',
        'LegalMatterRejected',
        'LegalMatterShared',
        'LegalMatterWithdrawn',
      ],
      [LawyerLegalMattersListingDocument],
      enqueueNotification,
    )
  }, [registerEvents, enqueueNotification, componentIdentifier])

  const handleOnCloseAlert = (): void => {
    navigate(location.pathname, {})
  }

  if (error !== undefined) {
    return (
      <ErrorScreen content="Sorry, we can not show results, try again later" />
    )
  }

  const onClickLoadMore = async (): Promise<void> => {
    if (data?.legalMatters.pageInfo.endCursor != null) {
      await fetchMore({
        variables: {
          cursor: data.legalMatters.pageInfo.endCursor,
        },
      })
    }
  }

  const renderNoResultsMessage = (filter: string): JSX.Element => {
    let message

    switch (filter) {
      case 'Referred':
        message = 'There are no referred legal matters.'
        break
      case 'Mine':
        message = 'There are no legal matters assigned to you.'
        break
      case 'Completed':
        message = 'There are no completed legal matters.'
        break
      case 'Withdrawn':
        message = 'There are no withdrawn legal matters.'
        break
      case 'Unclaimed':
        message = 'There are no unclaimed legal matters.'
        break
      case 'SharedWithMe':
        message = 'There are no shared legal matters.'
        break
      default:
        message = 'There are no legal matters.'
    }

    return (
      <Alert severity="info" icon={false}>
        <Typography gutterBottom>{message}</Typography>
      </Alert>
    )
  }

  return (
    <Box key="LawyerLegalMatterListing-wrapper" sx={{ paddingBottom: '24px' }}>
      <ConfirmationAlert
        open={displayAlert}
        onCloseAlert={handleOnCloseAlert}
        content={AlertContent[location.state]}
      />
      <Typography variant="h1" gutterBottom>
        Legal Matters
      </Typography>
      <Grid container justifyContent="space-between" alignItems="center">
        <Grid item mb={2}>
          <FiltersLegalMatters
            selectedStatus={filterName}
            statusList={statusMapper}
            onChange={handleStatusChange}
          />
        </Grid>
        <Grid item mb={2}>
          <Access requires={user.roles.includes('firms.creator')}>
            <FirmsDropdown onChange={handleFirmChange} firmRef={firmRef} />
          </Access>
        </Grid>
      </Grid>
      <Divider sx={{ mb: 2 }} />
      {(networkStatus === NetworkStatus.loading ||
        networkStatus === NetworkStatus.setVariables) && (
        <LegalMatterListLoading numberOfSkeletons={5} />
      )}
      {data?.legalMatters?.edges?.length === 0 &&
        renderNoResultsMessage(filterName)}
      {data?.legalMatters?.edges?.map((legalMatter, idx: number) => {
        return (
          <LegalMatterListItem
            key={`legal-matter-item-${legalMatter.node.key as string}`}
            data-testid="legal-matter-item"
            legalMatter={legalMatter.node}
            legalMatterStatusLabels={LawyerLegalMatterStatusLabels}
            textButton={
              legalMatter.node.status === LegalMatterStatus.Assigning ||
              legalMatter.node.status === LegalMatterStatus.Unassigned
                ? 'Review Request'
                : undefined
            }
            onClickButton={() => {
              navigate(
                routes.legalMatterDetail.param({
                  legalMatterKey: legalMatter.node.key,
                }),
              )
            }}
            onClickViewDetails={(legalMatter) => {
              navigate(
                routes.legalMatterDetail.param({
                  legalMatterKey: legalMatter.key,
                }),
              )
            }}
          />
        )
      })}
      {data != null && data.legalMatters.pageInfo.hasNextPage && (
        <LoadingButton
          loading={networkStatus === NetworkStatus.fetchMore}
          disableElevation
          variant="contained"
          color="primary"
          role="button"
          aria-label="Load More"
          onClick={onClickLoadMore}
          disabled={
            !data.legalMatters.pageInfo.hasNextPage ||
            networkStatus === NetworkStatus.fetchMore
          }
          data-testid="loading-button"
        >
          Load More
        </LoadingButton>
      )}
    </Box>
  )
}

export default LawyerLegalMatterListing
