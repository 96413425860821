import AnonymousUser from './AnonymousUser'
import FirmUser from './FirmUser'
import LegalMatter from './LegalMatter'
import AuthProviderUser from './AuthProviderUser'
import SubscriberUser from './SubscriberUser'
import BaseUser from './BaseUser'

const models = {
  LegalMatter,
  AnonymousUser,
  AuthProviderUser,
  SubscriberUser,
  FirmUser,
  BaseUser,
}

export default models
