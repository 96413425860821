import { type ErrorResponse } from '@apollo/client/link/error'
import { getGqlErrors } from './graphql-parsing-helpers'
import { GqlError } from '../@types/GqlError'
import { enqueueSnackbar } from 'notistack'
import log from 'loglevel'

const NOTIFICATION_MAP: Record<GqlError, any> = {
  [GqlError.InternalServerError]: {
    message: 'Something went wrong.',
    options: { variant: 'error' },
  },
  [GqlError.NetworkError]: {
    message: 'Something went wrong with our system. Please try again.',
    options: { variant: 'info', autoHideDuration: 10000 },
  },
  [GqlError.UserNotFound]: {
    message: 'User not found, redirecting to Login page.',
    options: { variant: 'error' },
  },
  [GqlError.DOWNSTREAM_SERVICE_ERROR]: {
    message:
      'We are experiencing some technical difficulties, please try again in a few seconds.',
    options: { variant: 'error', autoHideDuration: 5000 },
  },
}

const globalErrorHandler = (error: ErrorResponse): any => {
  let notification
  const code: string = getGqlErrors(error)
  log.warn('code', code)
  switch (code) {
    case GqlError.UserNotFound:
      notification = NOTIFICATION_MAP[GqlError.UserNotFound]
      enqueueSnackbar(notification.message, notification.options)
      break
    case GqlError.NetworkError:
      notification = NOTIFICATION_MAP[GqlError.NetworkError]
      break
    case GqlError.DOWNSTREAM_SERVICE_ERROR:
      notification = NOTIFICATION_MAP[GqlError.DOWNSTREAM_SERVICE_ERROR]
      enqueueSnackbar(notification.message, notification.options)
      break
    default:
      notification = NOTIFICATION_MAP[GqlError.InternalServerError]
      break
  }
  log.error(error)
}

export default globalErrorHandler
