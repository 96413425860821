import { useMemo } from 'react'
import { useQuery } from '@apollo/client'
import { MeDocument, MeQuery } from '../@types/generated/graphql'
import type User from '../models/User'
import models from '../models'
import { type Auth } from './useFirebaseAuth'
import log from 'loglevel'

interface Props {
  auth: Auth
  onError?: (error: Error) => void
}

export const defaultUser = new models.AnonymousUser()

export interface CurrentUser {
  loading: boolean
  user: User
}

export const useUserProfile = ({
  auth,
}: Props): {
  user: User
  loading: boolean
} => {
  const {
    data,
    loading: userLoading,
    error,
  } = useQuery<MeQuery>(MeDocument, {
    skip: !auth.isAuthenticated,
  })

  const loading = auth.authLoading || userLoading
  if (error != null) {
    log.error('Error fetching user profile', error)
  }

  return useMemo(() => {
    if (loading || data == null) {
      return { user: defaultUser, loading }
    }

    if (data?.me.__typename === 'BaseUser') {
      return {
        user: new models.BaseUser({
          photoURL: auth.user?.photoURL,
          ...data.me,
        }),
        loading,
      }
    } else if (data?.me.__typename === 'FirmUser') {
      return {
        user: new models.FirmUser({
          photoURL: auth.user?.photoURL,
          ...data.me,
        }),
        loading,
      }
    } else if (data?.me.__typename === 'SubscriberUser') {
      return {
        user: new models.SubscriberUser({
          photoURL: auth.user?.photoURL,
          ...data.me,
        }),
        loading,
      }
    } else {
      // This should catch cases when there is no user profile created yet
      if (auth.isAuthenticated && auth.user != null) {
        return { user: new models.AuthProviderUser(auth.user), loading }
      }
    }

    return { user: defaultUser, loading }
  }, [loading, auth.isAuthenticated, auth.user, data])
}

export default useUserProfile
