import React, { useContext, useEffect, useRef, useState } from 'react'
import { useNavigate, useParams, useSearchParams } from 'react-router-dom'
import { routes } from 'routes'
import { NetworkStatus, useQuery } from '@apollo/client'
import orderBy from 'lodash/orderBy'
import {
  LawyerLegalMatterDetailDocument,
  LegalMatterTasksDocument,
  CursorDirection,
  LegalMatterStatus,
  type CalendarEventResponse,
  type MutationError,
  Permission,
  IdentityKind,
  LegalMatterCalendarEventFragment,
  AssigneeFirmUserFragment,
} from '../../../@types/generated/graphql'
import { labelSlugs } from 'constants/index'
import CircularProgress from '@mui/material/CircularProgress'
import Box from '@mui/material/Box'
import Divider from '@mui/material/Divider'
import EditIcon from '@mui/icons-material/Edit'
import TextareaAutosize from '@mui/material/TextareaAutosize'
import DotMenu, { DotMenuAnchor } from 'view/components/atoms/DotMenu'
import LawyerNotes from 'view/components/data/LawyerNotes'
import LawyerNotesForm from 'view/components/atoms/LawyerNotesForm'
import LegalMatterRequestAction from 'view/components/molecules/LegalMatterRequestAction'
import ConfirmationDialog, {
  type ConfirmationDialogProps,
} from '../molecules/ConfirmationDialog'
import ClientContactInformation from 'view/components/molecules/ClientContactInformation'
import LegalMatterSidebar from 'view/components/organisms/LegalMatterSidebar'
import ThreeColumnLayout from 'view/components/templates/ThreeColumnLayout'
import TaskForm, {
  type SelectAssigneeProps,
} from 'view/components/molecules/TaskForm'
import CalendarEventListItem from 'view/components/organisms/CalendarEventListItem'
import EventForm from 'view/components/molecules/EventForm'
import Alert from '@mui/material/Alert'
import Button from '@mui/material/Button'
import Tab from '@mui/material/Tab'
import Typography from '@mui/material/Typography'
import AddIcon from '@mui/icons-material/Add'
import DeleteIcon from '@mui/icons-material/Delete'
import Access from 'view/components/molecules/Access'
import { LegalMatterLoading } from '../molecules/LoadingSkeletons'
import AnimatedList from 'view/components/molecules/AnimatedList'
import { resolveSubscriberName } from 'utils/StringFormatter'
import { paginationLimits } from 'config'
import TabContext from '@mui/lab/TabContext'
import TabList from '@mui/lab/TabList'
import TabPanel from '@mui/lab/TabPanel'
import {
  type DialogFiles,
  type Errors,
} from '../../../@types/DialogControlType'
import Grid from '@mui/material/Grid'
import { getPastDate } from 'utils/dateUtils'
import DropdownPeriodSelector from 'view/components/atoms/DropdownPeriodSelector'
import { useSnackbar } from 'notistack'
import LawyerTaskListing from 'view/components/data/LawyerTaskListing'
import IntakeFormStep from '../forms/IntakeFormStep'
import { SentryScopeContext } from 'view/providers/SentryScopeProvider'
import { LawyerLegalMatterStatusLabels } from 'utils/StatusMapper'
import log from 'loglevel'
import { EventProviderContext } from '../../providers/EventProvider'
import { useNotificationHandler } from 'utils/useNotificationHandler'
import UserPicker, {
  SelectedFirmUser,
  type updatedUsersIdentity,
} from '../molecules/UserPicker'
import model from 'models'
import { StoryblokRichText } from 'view/components/storyblok/StoryblokRenderers'
import useCurrentUser from 'utils/useCurrentUser'
import useCalendarEvents from 'utils/useCalendarEvents'
import useTasks from 'utils/useTasks'
import useLegalMatter from 'utils/useLegalMatter'
import useDocuments from 'utils/useDocuments'
import useNotes from 'utils/useNotes'
import FirmUser from 'models/FirmUser'
import ApplicationError from '../molecules/ApplicationError'
import AclEntity from 'models/AclEntity'
import Link from '@mui/material/Link'
import LoadingButton from '@mui/lab/LoadingButton'
import {
  FormControl,
  ListItemText,
  MenuItem,
  Select,
  SelectChangeEvent,
} from '@mui/material'
import UploadWidget from 'view/components/molecules/UploadWidget'

interface EventDotMenuAnchor extends DotMenuAnchor {
  event: LegalMatterCalendarEventFragment
}

interface CalendarEventServerErrorsInEdit {
  serverError?: string[]
}

type CalendarEventsInEdit = Record<string, CalendarEventServerErrorsInEdit>

interface AddNoteFormData {
  noteContent: string
  creationDate: string
}

interface DialogControlProps extends ConfirmationDialogProps {
  files?: DialogFiles
  errors?: Errors
  textBoxContent?: string
  documentKey?: string
  shareKeys?: updatedUsersIdentity[]
}

const defaultTaskFormData = {
  namePlaceholder: 'Enter task name',
  descriptionPlaceholder: 'Enter task description',
  textButton: 'Save',
  textCancelButton: 'Cancel',
  assignedTo: null,
}

type TabPanelType = 'INTAKEFORM' | 'LEGALMATTER'

const LawyerLegalMatter = (): JSX.Element | null => {
  const componentIdentifier = LawyerLegalMatter.name
  const { enqueueSnackbar, closeSnackbar } = useSnackbar()
  const { legalMatterKey } = useParams()
  const { user, loading: userLoading } = useCurrentUser()
  const { writeSentryError } = useContext(SentryScopeContext)
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()
  const { registerEvents, unregisterEvents } = useContext(EventProviderContext)
  const { enqueueNotification } = useNotificationHandler()
  const { deleteDocument, downloadDocument, uploadDocument } = useDocuments()

  useEffect(() => {
    return () => {
      unregisterEvents(componentIdentifier)
    }
  }, [unregisterEvents, componentIdentifier])

  useEffect(() => {
    registerEvents(
      componentIdentifier,
      [
        'CalendarEventCreated',
        'CalendarEventDeleted',
        'CalendarEventUpdated',
        'LegalMatterCancelled',
        'LegalMatterWithdrawn',
        'LegalMatterClosed',
        'LegalMatterDocumentDeleted',
        'LegalMatterDocumentUpdated',
        'LegalMatterShared',
        'LegalMatterShareRejected',
        'SubscriptionDeleted',
        'TaskCreated',
        'TaskCompleted',
      ],
      [LawyerLegalMatterDetailDocument, LegalMatterTasksDocument],
      enqueueNotification,
    )
  }, [registerEvents, enqueueNotification, componentIdentifier])

  const [selectedTab, setSelectedTab] = useState<TabPanelType>()

  const [lastCalendarEvent, setLastCalendarEvent] = useState(null)

  const dialogControlIntialState: DialogControlProps = {
    open: false,
    title: <></>,
    content: <></>,
    actions: <></>,
    documentKey: '',
    shareKeys: [],
    textBoxContent: undefined,
    errors: {
      message: undefined,
    },
  }

  const [dialogControlProps, setDialogControlProps] = useState(
    dialogControlIntialState,
  )

  const [eventsPeriod, setEventsPeriod] = useState<number>(7)
  const [eventsPeriodDateTime, setEventsPeriodDateTime] = useState<
    string | Date | null
  >(getPastDate(eventsPeriod, 'UTC'))

  const [addNewEventMode, setAddNewEventMode] = useState<boolean>(false)
  const [addNewEventError, setAddNewEventError] = useState<string[]>([])
  const [legalMatterCalendarEventsInEdit, setLegalMatterCalendarEventsInEdit] =
    useState<CalendarEventsInEdit>({})

  const [addNewTaskMode, setAddNewTaskMode] = useState<boolean>(false)
  const [addNewTaskError, setAddNewTaskError] = useState<string[]>([])

  const [dotMenuAnchor, setDotMenuAnchor] = useState<EventDotMenuAnchor | null>(
    null,
  )

  const [selectedAssignedLawyer, setSelectedAssignedLawyer] =
    useState<string>('')

  const handleAssignedLawyerChange = (event: SelectChangeEvent) => {
    setSelectedAssignedLawyer(event.target.value)
  }

  useEffect(() => {
    setDialogControlProps((prevState) => ({
      ...prevState,
      content: (
        <>
          <Typography>
            Please select an attorney to assign this legal matter:
          </Typography>
          <FormControl fullWidth>
            <Select
              data-testid="assignLawyer-dropdown"
              value={selectedAssignedLawyer}
              displayEmpty
              renderValue={(selected) => {
                if (selectedAssignedLawyer == '') return 'Select an Attorney'
                const lawyer = lawyersList.find(
                  (lawyer) => lawyer.key === selected,
                )
                return lawyer ? `${lawyer.firstName} ${lawyer.lastName}` : ''
              }}
              label="Select Lawyer"
              onChange={handleAssignedLawyerChange}
              sx={{ marginTop: '12px' }}
            >
              <MenuItem disabled value="" data-testid="asign-attorney-item">
                <ListItemText primary="Select an Attorney" />
              </MenuItem>
              {lawyersList.map((lawyer) => {
                return (
                  <MenuItem
                    key={lawyer.key}
                    value={lawyer.key}
                    data-testid="asign-attorney-item"
                  >
                    <ListItemText
                      primary={
                        <Box
                          sx={{
                            width: '100%',
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'space-between',
                          }}
                        >
                          <span style={{ width: '50%', textWrap: 'initial' }}>
                            {lawyer.firstName} {lawyer.lastName}
                          </span>
                          <span>
                            Assigned:{' '}
                            {lawyer.numberOfAssignedLegalMatters
                              ? lawyer.numberOfAssignedLegalMatters
                              : 0}{' '}
                          </span>
                          <span>
                            Waiting:{' '}
                            {lawyer.numberOfAssigningLegalMatters
                              ? lawyer.numberOfAssigningLegalMatters
                              : 0}{' '}
                          </span>
                        </Box>
                      }
                    />
                  </MenuItem>
                )
              })}
            </Select>
          </FormControl>
        </>
      ),
      actions: (
        <>
          <Button
            onClick={() => {
              handleDialogActionConfirm('assignLawyer')
            }}
            autoFocus
            variant="contained"
            color="primary"
            data-testid="confirm-dialog-btn-accept"
            disabled={selectedAssignedLawyer == ''}
          >
            Assign the Legal Matter
          </Button>
        </>
      ),
    }))
  }, [selectedAssignedLawyer])

  const eventInUpdateRef = useRef('')
  const selectAssigneeValues = useRef<SelectAssigneeProps[]>([])

  const downloadRef = useRef<HTMLAnchorElement>(null)

  const taskFormDefaultProps = useRef(defaultTaskFormData)

  const handleDialogActionConfirm = (
    action: string,
    documentKey = '',
  ): void => {
    switch (action) {
      case 'acceptReferrMatter':
        setDialogControlProps((prevState) => ({
          ...prevState,
          open: true,
          title: (
            <>
              <Typography variant="h1" gutterBottom>
                Accepting refered legal matter
              </Typography>
            </>
          ),
          content: (
            <>
              <Typography>Please wait...</Typography>
            </>
          ),
          actions: <></>,
        }))
        void acceptReferredLegalMatter({
          variables: {
            legalMatterKey,
          },
          onCompleted: (data) => {
            if (data.legalMatterReferralAccept.success != null) {
              enqueueSnackbar(
                'The referred legal matter has been accepted and is now on status of UNASSIGNED',
                {
                  variant: 'success',
                },
              )
              setDialogControlProps(dialogControlIntialState)
              // navigate(routes.legalMatterListing.path)
            } else {
              enqueueSnackbar(data.legalMatterReferralAccept.message, {
                variant: 'error',
              })
            }
          },
          onError: () => {
            enqueueSnackbar(
              'There was an error while accepting the legal matter, try again in a few seconds',
              {
                variant: 'error',
              },
            )
          },
        })
        break
      case 'rejectReferrMatter':
        setDialogControlProps((currentDialogControlProps) => {
          if (
            currentDialogControlProps.textBoxContent !== undefined &&
            currentDialogControlProps.textBoxContent !== ''
          ) {
            void rejectReferredLegalMatter({
              variables: {
                legalMatterKey,
                rejectionReason: currentDialogControlProps.textBoxContent,
              },
              onCompleted: (data) => {
                if (data.legalMatterReferralReject.success != null) {
                  setDialogControlProps(dialogControlIntialState)
                  navigate(routes.legalMatterListing.path, {
                    state: 'REJECTED',
                  })
                } else {
                  const errors =
                    data?.legalMatterReferralReject as MutationError
                  setDialogControlProps({
                    ...dialogControlProps,
                    errors: {
                      message: errors?.message,
                    },
                  })
                }
              },
            })
            return currentDialogControlProps
          } else {
            return {
              ...currentDialogControlProps,
              errors: {
                message: 'You have to state a reason first.',
              },
            }
          }
        })
        break
      case 'closeLegalMatter':
        setDialogControlProps((prevState) => ({
          ...prevState,
          open: true,
          title: (
            <>
              <Typography variant="h1" gutterBottom>
                Closing this legal matter
              </Typography>
            </>
          ),
          content: (
            <>
              <Typography>Please wait...</Typography>
            </>
          ),
          actions: <></>,
        }))
        void closeLegalMatter({
          variables: {
            legalMatterKey,
          },
          onCompleted: () => {
            setDialogControlProps(dialogControlIntialState)
          },
          onError: (error) => {
            setDialogControlProps((prevState) => ({
              ...prevState,
              errors: { message: error.message },
            }))
            enqueueSnackbar(
              'There was a problem in closing this matter, try again in a few seconds',
              {
                variant: 'error',
              },
            )
          },
        })
        break
      case 'assignLawyer':
        setDialogControlProps((prevState) => ({
          ...prevState,
          open: true,
          title: (
            <>
              <Typography variant="h1" gutterBottom>
                Assigning legal matter
              </Typography>
            </>
          ),
          content: (
            <>
              <Typography>Please wait...</Typography>
            </>
          ),
          actions: <></>,
        }))
        void assignLegalMatter({
          variables: { legalMatterKey, lawyerKey: selectedAssignedLawyer },
          onCompleted: (data) => {
            if (data.legalMatterAssign.success != null) {
              enqueueSnackbar(
                'The legal matter has been successfully assigned.',
                {
                  variant: 'success',
                },
              )
              navigate(routes.legalMatterListing.path)
            } else {
              enqueueSnackbar(data.legalMatterAssign.message, {
                variant: 'error',
              })
            }
          },
          onError: () => {
            enqueueSnackbar(
              'There was an error while assigning the legal matter, try again in a few seconds',
              {
                variant: 'error',
              },
            )
          },
        })
        break
      case 'acceptRequest':
        setDialogControlProps((prevState) => ({
          ...prevState,
          open: true,
          title: (
            <>
              <Typography variant="h1" gutterBottom>
                Accepting this legal matter
              </Typography>
            </>
          ),
          content: (
            <>
              <Typography>Please wait...</Typography>
            </>
          ),
          actions: <></>,
        }))
        void acceptLegalMatter({
          variables: {
            legalMatterKey,
          },
          onCompleted: () => {
            setDialogControlProps(dialogControlIntialState)
          },
          onError: () => {
            enqueueSnackbar(
              'There was a problem in processing the request, try again in a few seconds',
              {
                variant: 'error',
              },
            )
            setDialogControlProps(dialogControlIntialState)
          },
        })
        break
      case 'claimRequest':
        setDialogControlProps((prevState) => ({
          ...prevState,
          open: true,
          title: (
            <>
              <Typography variant="h1" gutterBottom>
                Claiming legal matter
              </Typography>
            </>
          ),
          content: (
            <>
              <Typography>Please wait...</Typography>
            </>
          ),
          actions: <></>,
        }))
        void claimLegalMatter({
          variables: {
            legalMatterKey,
          },
          onCompleted: () => {
            setDialogControlProps(dialogControlIntialState)
          },
          onError: () => {
            enqueueSnackbar(
              'There was an error while accepting your request, try again in a few seconds.',
              {
                variant: 'error',
              },
            )
            setDialogControlProps(dialogControlIntialState)
          },
        })
        break
      case 'rejectRequest':
        setDialogControlProps((currentDialogControlProps) => {
          if (currentDialogControlProps.textBoxContent !== undefined) {
            void rejectLegalMatter({
              variables: {
                legalMatterKey,
                rejectionReason: currentDialogControlProps.textBoxContent,
              },
              onCompleted: (data) => {
                if (data.legalMatterReject.success) {
                  setDialogControlProps(dialogControlIntialState)
                  navigate(routes.legalMatterListing.path, {
                    state: 'REJECTED',
                  })
                } else {
                  const errors = data?.legalMatterReject as MutationError
                  setDialogControlProps({
                    ...dialogControlProps,
                    errors: {
                      message: errors?.message,
                    },
                  })
                }
              },
              onError: () => {
                enqueueSnackbar(
                  'There was a problem in processing the request, try again in a few seconds',
                  {
                    variant: 'error',
                  },
                )
                setDialogControlProps(dialogControlIntialState)
              },
            })
            return currentDialogControlProps
          } else {
            return {
              ...currentDialogControlProps,
              errors: {
                message: 'You have to state a reason first.',
              },
            }
          }
        })
        break
      case 'withdrawnRequest':
        setDialogControlProps((currentDialogControlProps) => {
          if (currentDialogControlProps.textBoxContent !== undefined) {
            void withdrawLegalMatter({
              variables: {
                legalMatterKey,
                withdrawReason: currentDialogControlProps.textBoxContent,
              },
              onCompleted: () => {
                navigate(routes.legalMatterListing.path, {
                  state: LegalMatterStatus.Withdrawn,
                })
                setDialogControlProps(dialogControlIntialState)
              },
              onError: () => {
                enqueueSnackbar(
                  'There was a problem in processing the request, try again in a few seconds',
                  {
                    variant: 'error',
                  },
                )
              },
            })
            return currentDialogControlProps
          } else {
            return {
              ...currentDialogControlProps,
              errors: {
                message: 'You have to state a reason first.',
              },
            }
          }
        })
        break
      case 'deleteEvent':
        if (dotMenuAnchor == null) return
        void deleteEvent({
          variables: {
            calendarEventKey: dotMenuAnchor.event.key,
          },
          onCompleted: () => {
            setDialogControlProps(dialogControlIntialState)
          },
          onError: () => {
            setDialogControlProps(dialogControlIntialState)
            enqueueSnackbar(
              'There was a problem in deleting the event, try again in a few seconds',
              {
                variant: 'error',
              },
            )
          },
        })
        break
      case 'deleteAttachedDocument':
        if (documentKey !== '') {
          void deleteDocument({
            variables: {
              documentKey,
            },
            onCompleted: (data) => {
              if (!data.legalMatterDocumentDelete.success) {
                enqueueSnackbar(data.legalMatterDocumentDelete.message, {
                  variant: 'error',
                })
              }
              setDialogControlProps(dialogControlIntialState)
            },
            onError: () => {
              setDialogControlProps(dialogControlIntialState)
              enqueueSnackbar(
                'There was an error in deleting the file, try again in a few seconds',
                {
                  variant: 'error',
                },
              )
            },
          })
        }
        break
      case 'shareLegalMatter':
        setDialogControlProps((currentDialogControlProps) => {
          void shareLegalMatter({
            variables: {
              input: {
                legalMatterKey,
                userAcls: currentDialogControlProps.shareKeys ?? [],
                userMessage: currentDialogControlProps.textBoxContent ?? '',
              },
            },
            onCompleted: (data) => {
              if (data.legalMatterShare.success != null) {
                enqueueSnackbar('The legal Matter was shared successfully', {
                  variant: 'success',
                })
              } else {
                // notistack error
                const errors = data?.legalMatterShare as MutationError
                setAddNewEventError([
                  ...addNewEventError,
                  errors.userErrors.length > 0
                    ? errors.userErrors[0]?.fieldErrors[0]?.errors[0]?.message
                    : errors.message,
                ])
              }
            },
            onError: (error) => {
              writeSentryError(
                error,
                {
                  source: 'apollo_mutation',
                  mutation_name: 'legalMatterShare',
                },
                error.message,
              )
              enqueueSnackbar(
                'There was a problem in sharing the Legal Matter, try again in a few seconds',
                {
                  variant: 'error',
                },
              )
            },
          })
          return dialogControlProps
        })
        break
      case 'shareReject':
        setDialogControlProps((currentDialogControlProps) => {
          void shareRejectLegalMatter({
            variables: {
              legalMatterKey,
              userMessage: currentDialogControlProps.textBoxContent ?? '',
            },
            onCompleted: (data) => {
              if (data.legalMatterShareReject.success != null) {
                enqueueSnackbar('You have been removed successfully', {
                  variant: 'success',
                })
                navigate({ pathname: routes.legalMatterListing.path })
              } else {
                // notistack error
                const errors = data?.legalMatterShareReject as MutationError
                setAddNewEventError([
                  ...addNewEventError,
                  errors.userErrors.length > 0
                    ? errors.userErrors[0]?.fieldErrors[0]?.errors[0]?.message
                    : errors.message,
                ])
              }
            },
            onError: (error) => {
              writeSentryError(
                error,
                {
                  source: 'apollo_mutation',
                  mutation_name: 'legalMatterShareReject',
                },
                error.message,
              )
              enqueueSnackbar(
                'There was a problem removing the user from the Legal Matter, try again in a few seconds',
                {
                  variant: 'error',
                },
              )
            },
          })
          return dialogControlProps
        })
        break
      default:
    }
  }

  const { data, networkStatus, refetch, fetchMore } = useQuery(
    LawyerLegalMatterDetailDocument,
    {
      variables: {
        key: legalMatterKey,
        since: eventsPeriodDateTime,
        includeSubscriptionDocument: true,
        calendarLimit: paginationLimits.calendarEvents,
        calendarCursor: null,
        calendarCursorDirection: CursorDirection.Next,
        documentsLimit: paginationLimits.documents,
        documentsCursor: null,
        documentsCursorDirection: CursorDirection.Next,
        notesLimit: paginationLimits.notes,
        notesCursor: null,
        notesCursorDirection: CursorDirection.Next,
      },
      notifyOnNetworkStatusChange: true,
      skip: userLoading,
      onCompleted: (data) => {
        taskFormDefaultProps.current.assignedTo =
          data.legalMatters.edges[0].node.assignedLawyer?.key
      },
    },
  )

  const legalMatter = data?.legalMatters?.edges?.[0]?.node
  const lawyersList =
    data?.firmUsers?.edges?.map(
      (edge) => edge.node as AssigneeFirmUserFragment,
    ) ?? []
  const subscriptions = legalMatter?.subscriber?.subscriptions ?? []
  const subscribedProducts =
    subscriptions.length > 0 ? subscriptions.flatMap((sub) => sub.products) : []

  const {
    assignLegalMatter,
    acceptReferredLegalMatter,
    rejectReferredLegalMatter,
    acceptLegalMatter,
    claimLegalMatter,
    closeLegalMatter,
    rejectLegalMatter,
    shareLegalMatter,
    shareRejectLegalMatter,
    withdrawLegalMatter,
  } = useLegalMatter()
  const { createEvent, updateEvent, deleteEvent, eventLoading } =
    useCalendarEvents(legalMatterKey)
  const { createTask, taskLoading } = useTasks(legalMatterKey)
  const { createNote } = useNotes(legalMatterKey)

  if (userLoading || networkStatus == NetworkStatus.loading) {
    return <LegalMatterLoading />
  }

  if (!(user instanceof FirmUser)) {
    return (
      <ApplicationError
        message={
          <Typography>
            No valid user could be resolved. Please reload in a few seconds.
          </Typography>
        }
      />
    )
  }

  if (!data || legalMatter == null) {
    return (
      <ApplicationError
        message={
          <Typography>
            This legal matter does not exist.{' '}
            <Link
              onClick={() => {
                navigate(routes.legalMatterListing.path)
              }}
            >
              Go back to My Legal Matters.
            </Link>
          </Typography>
        }
      />
    )
  }

  const handleSubmitNoteData = (data: AddNoteFormData): void => {
    if (user.key == null) {
      throw new Error('User is not defined')
    }
    void createNote({
      variables: {
        text: data?.noteContent,
        legalMatterKey,
        // @TODO (enlore): This is a compatability issue and needs to be removed
        createdByKey: user.key,
      },
      onCompleted: (data) => {
        if (data.noteCreate.success) {
          enqueueSnackbar('Note created successfully', {
            variant: 'success',
          })
          setDialogControlProps(dialogControlIntialState)
        }
      },
      onError: () => {
        setDialogControlProps(dialogControlIntialState)
        enqueueSnackbar(
          'There was a problem in processing the request, try again in a few seconds',
          {
            variant: 'error',
          },
        )
      },
    })
  }

  const legalMatterModel = new model.LegalMatter(legalMatter)
  const calendarEvents = orderBy(
    legalMatter.calendarEvents.edges.map((edge) => edge.node),
    ['node.startTime'],
    ['desc'],
  )
  const documents = legalMatter.documents.edges.map((edge) => edge.node)
  const firmUsers = data?.firmUsers.edges.map((edge) => edge.node) ?? []

  const isAssigning =
    legalMatterModel.assignmentInProgress() &&
    legalMatterModel.acl.allows(
      user.aclIdentities,
      Permission.LegalMattersAccept,
    )
  const isBeingClaimed =
    legalMatterModel.canBeClaimed() &&
    legalMatterModel.acl.allows(
      user.aclIdentities,
      Permission.LegalMattersClaim,
    )
  const canCreateNotes = legalMatterModel.acl.allows(
    user.aclIdentities,
    Permission.NotesCreate,
  )
  const canAssign = legalMatterModel.acl.allows(
    user.aclIdentities,
    Permission.LegalMattersAssign,
  )
  const canClaim = legalMatterModel.acl.allows(
    user.aclIdentities,
    Permission.LegalMattersClaim,
  )

  const defaultTab: TabPanelType =
    isAssigning || isBeingClaimed ? 'INTAKEFORM' : 'LEGALMATTER'

  const handleEventDotMenuClick = (
    event: React.MouseEvent,
    calendarEvent: LegalMatterCalendarEventFragment,
  ): void => {
    const eventAcl = new AclEntity(calendarEvent.acl)
    setDotMenuAnchor({
      event: calendarEvent,
      element: event.currentTarget,
      menuItems: eventsDotMenuOptions.map((menuOpt) => ({
        ...menuOpt,
        disabled: !eventAcl.allows(
          user.aclIdentities,
          menuOpt.requiredPermission,
        ),
      })),
    })
  }

  const handleTaskDotMenuSelection = (action: string): void => {
    switch (action) {
      case 'editEvent':
        setLegalMatterCalendarEventsInEdit({
          ...legalMatterCalendarEventsInEdit,
          [dotMenuAnchor?.event?.key]: { serverError: [] },
        })
        break
      case 'deleteEvent':
        setDialogControlProps({
          ...dialogControlProps,
          open: true,
          title: (
            <>
              <Typography variant="h1" gutterBottom>
                Are you sure you want to proceed ?
              </Typography>
            </>
          ),
          content: (
            <>
              <Typography>
                You are about to delete the event{' '}
                {dotMenuAnchor?.event?.name != null
                  ? dotMenuAnchor?.event.name
                  : ''}
              </Typography>
            </>
          ),
          actions: (
            <>
              <Button
                onClick={() => {
                  setDialogControlProps(dialogControlIntialState)
                }}
                autoFocus
                variant="contained"
                color="error"
                data-testid="confirm-dialog-btn-cancel"
              >
                Cancel
              </Button>
              <Button
                onClick={() => {
                  handleDialogActionConfirm('deleteEvent')
                }}
                autoFocus
                variant="contained"
                color="primary"
                data-testid="confirm-dialog-btn-accept"
              >
                Confirm
              </Button>
            </>
          ),
        })
        break
      default:
    }
    setDotMenuAnchor(null)
  }

  const handleCancelAddingTask = (): void => {
    setAddNewTaskMode(false)
    taskFormDefaultProps.current = defaultTaskFormData
  }
  const handleCancelAddingEvent = (eventKey): void => {
    setAddNewEventMode(false)
    setAddNewEventError([])
    setLegalMatterCalendarEventsInEdit((prevState) => {
      const { [eventKey]: _, ...newState } = prevState
      return newState
    })
  }
  const handleChangeEventStartTime = (value, eventKey?: string): void => {
    setAddNewEventError([])
    if (eventKey !== undefined) {
      setLegalMatterCalendarEventsInEdit({
        ...legalMatterCalendarEventsInEdit,
        [eventKey]: {
          serverError: [],
        },
      })
    }
  }
  const handleChangeEventEndTime = (value, eventKey?: string): void => {
    setAddNewEventError([])
    if (eventKey !== undefined) {
      setLegalMatterCalendarEventsInEdit({
        ...legalMatterCalendarEventsInEdit,
        [eventKey]: {
          serverError: [],
        },
      })
    }
  }

  const finishEventEditing = (eventKey: string): void => {
    const { [eventKey]: _, ...filtered } = legalMatterCalendarEventsInEdit
    setLegalMatterCalendarEventsInEdit(filtered)
  }

  const handleDocumentDownload = (documentPath: string): void => {
    const downloadJob = downloadDocument(documentPath)
    const snackKey = enqueueSnackbar('Downloading ...', {
      variant: 'info',
    })
    downloadJob.onComplete(({ fileName, objectUrl }) => {
      return new Promise((resolve) => {
        if (downloadRef.current) {
          // Set the href to the blob URL and the download attribute to the file name
          downloadRef.current.href = objectUrl
          downloadRef.current.download = fileName!

          // Trigger the download
          downloadRef.current?.click()
          resolve()
          closeSnackbar(snackKey)
        }
      })
    })
    downloadJob.onError(({ error }) => {
      enqueueSnackbar(error.message, {
        variant: 'error',
      })
    })
  }

  const handleDocumentDelete = (documentKey: string): void => {
    const document = documents.find((d) => d.key === documentKey)
    if (document !== undefined) {
      setDialogControlProps({
        open: true,
        title: (
          <>
            <Typography variant="h1" gutterBottom>
              Confirm deletion of document
            </Typography>
          </>
        ),
        content: (
          <>
            <Typography>
              Are you sure you want to delete the document{' '}
              <strong>{document.name}</strong> ?
            </Typography>
          </>
        ),
        actions: (
          <>
            <Button
              onClick={() => {
                setDialogControlProps(dialogControlIntialState)
              }}
              autoFocus
              variant="contained"
              color="error"
              data-testid="confirm-dialog-btn-cancel"
            >
              Cancel deletion
            </Button>
            <Button
              onClick={() => {
                handleDialogActionConfirm('deleteAttachedDocument', documentKey)
              }}
              autoFocus
              variant="contained"
              color="primary"
              data-testid="confirm-dialog-btn-accept"
            >
              Confirm deletion
            </Button>
          </>
        ),
      })
    }
  }

  const handleTabChange = (
    event: React.SyntheticEvent,
    newSelectedTab: TabPanelType,
  ): void => {
    setSelectedTab(newSelectedTab)
  }

  const onClickAcceptRequest = (): void => {
    setDialogControlProps({
      ...dialogControlProps,
      open: true,
      title: (
        <>
          <Typography variant="h1" gutterBottom>
            Confirm Acceptance
          </Typography>
        </>
      ),
      content: (
        <>
          <Typography>
            I confirm that I have completed a conflict of interest check and
            determined the firm has no conflict in accepting this legal matter.
          </Typography>
        </>
      ),
      actions: (
        <>
          <Button
            onClick={() => {
              handleDialogActionConfirm('acceptRequest')
            }}
            autoFocus
            variant="contained"
            color="primary"
            data-testid="confirm-dialog-btn-accept"
          >
            Accept This New Matter
          </Button>
        </>
      ),
    })
  }

  const onClickDeclineRequest = (): void => {
    setDialogControlProps((prevState) => ({
      ...prevState,
      open: true,
      title: (
        <>
          <Typography variant="h1" gutterBottom>
            Confirm rejection
          </Typography>
          <Typography variant="h2" gutterBottom>
            State why you rejected the request
          </Typography>
        </>
      ),
      content: (
        <>
          {dialogControlProps.errors?.message !== undefined &&
            dialogControlProps.errors?.message !== '' && (
              <Alert variant="outlined" severity="error">
                {dialogControlProps.errors.message}
              </Alert>
            )}
          <Typography variant="h4" gutterBottom>
            Please do not include attorney-client privileged information
          </Typography>
          <TextareaAutosize
            minRows={10}
            style={{ width: '97%' }}
            data-testid="reject-dialog-textarea"
            onChange={(e) => {
              setDialogControlProps((prevDialogControlProps) => ({
                ...prevDialogControlProps,
                textBoxContent: e.target.value,
              }))
            }}
          />
        </>
      ),
      actions: (
        <>
          <Button
            onClick={() => {
              setDialogControlProps(dialogControlIntialState)
            }}
            autoFocus
            variant="contained"
            color="error"
            data-testid="confirm-dialog-btn-cancel"
          >
            Cancel
          </Button>
          <Button
            onClick={() => {
              handleDialogActionConfirm('rejectRequest')
            }}
            autoFocus
            variant="contained"
            color="primary"
            data-testid="confirm-dialog-btn-accept"
          >
            Confirm Rejection
          </Button>
        </>
      ),
    }))
  }

  const onClickClaimRequest = (): void => {
    setDialogControlProps((prevState) => ({
      ...prevState,
      open: true,
      title: (
        <>
          <Typography variant="h1" gutterBottom>
            Confirm Acceptance
          </Typography>
        </>
      ),
      content: (
        <>
          <Typography>
            I confirm that I have completed a conflict of interest check and
            determined the firm has no conflict in accepting this legal matter.
          </Typography>
        </>
      ),
      actions: (
        <>
          <Button
            onClick={() => {
              handleDialogActionConfirm('claimRequest')
            }}
            autoFocus
            variant="contained"
            color="primary"
            data-testid="confirm-dialog-btn-accept"
          >
            Accept This New Matter
          </Button>
        </>
      ),
    }))
  }

  const onClickAssignLawyer = (): void => {
    setDialogControlProps((prevState) => ({
      ...prevState,
      open: true,
      title: (
        <>
          <Typography variant="h1" gutterBottom>
            Assign an Attorney
          </Typography>
        </>
      ),
      content: (
        <>
          <Typography>
            Please select an attorney to assign this legal matter:
          </Typography>
          <FormControl fullWidth>
            <Select
              data-testid="assignLawyer-dropdown"
              value={selectedAssignedLawyer}
              displayEmpty
              renderValue={(selected) => {
                if (selectedAssignedLawyer == '') return 'Select an Attorney'
                const lawyer = lawyersList.find(
                  (lawyer) => lawyer.key === selected,
                )
                return lawyer ? `${lawyer.firstName} ${lawyer.lastName}` : ''
              }}
              label="Select Lawyer"
              onChange={handleAssignedLawyerChange}
              sx={{ marginTop: '12px' }}
            >
              <MenuItem value="" disabled data-testid="asign-attorney-item">
                <ListItemText primary="Select an Attorney" />
              </MenuItem>
              {lawyersList.map((lawyer) => {
                return (
                  <MenuItem
                    key={lawyer.key}
                    value={lawyer.key}
                    data-testid="asign-attorney-item"
                  >
                    <ListItemText
                      primary={
                        <Box
                          sx={{
                            width: '100%',
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'space-between',
                          }}
                        >
                          <span style={{ width: '50%', textWrap: 'initial' }}>
                            {lawyer.firstName} {lawyer.lastName}
                          </span>
                          <span>
                            Assigned:{' '}
                            {lawyer.numberOfAssignedLegalMatters
                              ? lawyer.numberOfAssignedLegalMatters
                              : 0}{' '}
                          </span>
                          <span>
                            Waiting:{' '}
                            {lawyer.numberOfAssigningLegalMatters
                              ? lawyer.numberOfAssigningLegalMatters
                              : 0}{' '}
                          </span>
                        </Box>
                      }
                    />
                  </MenuItem>
                )
              })}
            </Select>
          </FormControl>
        </>
      ),
      actions: (
        <>
          <Button
            onClick={() => {
              handleDialogActionConfirm('assignLawyer')
            }}
            autoFocus
            variant="contained"
            color="primary"
            data-testid="confirm-dialog-btn-accept"
            disabled={selectedAssignedLawyer == ''}
          >
            Assign the Legal Matter
          </Button>
        </>
      ),
    }))
  }

  const usersWithPermissionsOnThisMatter =
    legalMatterModel.acl.getAclKeysByKind(IdentityKind.User) ?? []

  const shareUserListing = firmUsers
    .filter((user) => ['FirmUser', 'LawyerUser'].includes(user.__typename!))
    .map((user) => {
      return {
        ...user,
        checked: usersWithPermissionsOnThisMatter.includes(user.key),
        disabled: user.key === legalMatter.assignedLawyer?.key,
      } as SelectedFirmUser
    })

  const handleShareLawyer = (): void => {
    setDialogControlProps((prevState) => ({
      ...prevState,
      open: true,
      title: (
        <Typography variant="h1" gutterBottom>
          Share this Legal Matter to another Lawyer
        </Typography>
      ),
      content: (
        <>
          <UserPicker
            users={shareUserListing}
            onSelectionChange={(updatedUsers) => {
              setDialogControlProps((prevState) => ({
                ...prevState,
                shareKeys: updatedUsers,
              }))
            }}
          />
          <Typography variant="h5" gutterBottom>
            Why do you want to share it?
          </Typography>
          <TextareaAutosize
            minRows={10}
            style={{ width: '98%' }}
            data-testid="refer-dialog-textarea"
            onChange={(e) => {
              setDialogControlProps((prevDialogControlProps) => ({
                ...prevDialogControlProps,
                textBoxContent: e.target.value,
              }))
            }}
          />
        </>
      ),
      actions: (
        <>
          <Button
            onClick={() => {
              setDialogControlProps(dialogControlIntialState)
            }}
            autoFocus
            variant="contained"
            color="error"
            data-testid="confirm-dialog-btn-cancel"
          >
            Cancel
          </Button>
          <Button
            onClick={() => {
              handleDialogActionConfirm('shareLegalMatter')
            }}
            autoFocus
            variant="contained"
            color="primary"
            data-testid="confirm-dialog-btn-accept"
          >
            Share
          </Button>
        </>
      ),
    }))
  }

  const handleRemoveLawyer = (): void => {
    setDialogControlProps((prevState) => ({
      ...prevState,
      open: true,
      title: (
        <>
          <Typography variant="h1" gutterBottom>
            Confirm removal from this legal matter?
          </Typography>
        </>
      ),
      content: (
        <>
          {dialogControlProps.errors?.message !== undefined &&
            dialogControlProps.errors?.message !== '' && (
              <Alert variant="outlined" severity="error">
                {dialogControlProps.errors.message}
              </Alert>
            )}
          <TextareaAutosize
            minRows={10}
            placeholder="Please explain your reason for leaving this matter..."
            style={{ width: '97%' }}
            onChange={(e) => {
              setDialogControlProps((prevState) => ({
                ...prevState,
                textBoxContent: e.target.value,
              }))
            }}
          />
        </>
      ),
      actions: (
        <>
          <Button
            onClick={() => {
              setDialogControlProps(dialogControlIntialState)
            }}
            autoFocus
            variant="contained"
            color="error"
            data-testid="confirm-dialog-btn-cancel"
          >
            Cancel
          </Button>
          <Button
            onClick={() => {
              if (user.key === legalMatter.assignedLawyer?.key) {
                handleDialogActionConfirm('withdrawnRequest')
              } else {
                handleDialogActionConfirm('shareReject')
              }
            }}
            autoFocus
            variant="contained"
            color="primary"
            data-testid="confirm-dialog-btn-accept"
          >
            Confirm Removal
          </Button>
        </>
      ),
    }))
  }

  const handleCloseThisMatter = (): void => {
    setDialogControlProps({
      ...dialogControlProps,
      open: true,
      title: (
        <>
          <Typography variant="h1" gutterBottom>
            Confirm to close this legal matter
          </Typography>
        </>
      ),
      content: (
        <>
          <Typography>
            Are you sure you want to close this legal matter?
          </Typography>
        </>
      ),
      actions: (
        <>
          <Button
            onClick={() => {
              setDialogControlProps({ ...dialogControlProps, open: false })
            }}
            autoFocus
            variant="contained"
            color="error"
            data-testid="confirm-dialog-btn-cancel"
          >
            Cancel
          </Button>
          <Button
            onClick={() => {
              handleDialogActionConfirm('closeLegalMatter')
            }}
            autoFocus
            variant="contained"
            color="primary"
            data-testid="confirm-dialog-btn-accept"
          >
            Confirm
          </Button>
        </>
      ),
    })
  }

  const handleEventPeriodChange = (value: number): void => {
    setEventsPeriod(value)
    setEventsPeriodDateTime(getPastDate(value, 'UTC'))
  }

  const eventsDotMenuOptions = [
    {
      action: 'editEvent',
      label: 'Edit Event',
      icon: <EditIcon />,
      requiredPermission: Permission.CalendarEventsUpdate,
    },
    {
      action: 'deleteEvent',
      label: 'Delete Event',
      icon: <DeleteIcon />,
      requiredPermission: Permission.CalendarEventsDelete,
    },
  ]

  const handleSubscribedProductLinkClick = (product, idx): void => {
    // Open modal first to tell the user that we are fetching the content
    setDialogControlProps((prevState) => ({
      ...prevState,
      open: true,
      title: (
        <>
          <Typography variant="h1" gutterBottom>
            {product.name}
          </Typography>
        </>
      ),
      content: <CircularProgress />,
      actions: (
        <>
          <Button
            onClick={() => {
              onClickOpenFullDocument(idx)
            }}
            autoFocus
            variant="contained"
            color="primary"
            data-testid="confirm-dialog-btn-accept"
          >
            See Full Contract
          </Button>
          <Button
            onClick={() => {
              setDialogControlProps(dialogControlIntialState)
            }}
            autoFocus
            variant="contained"
            color="error"
            data-testid="confirm-dialog-btn-cancel"
          >
            Close
          </Button>
        </>
      ),
    }))

    // Do refetch and update the modal with the content fetched
    refetch({
      includeSubscriptionDocument: true,
    })
      .then(() => {
        setDialogControlProps((prevState) => ({
          ...prevState,
          content: (
            <StoryblokRichText
              document={subscribedProducts?.[idx]?.storyblok?.content?.summary}
            />
          ),
        }))
      })
      .catch((error) => {
        log.warn('Error while refetching:', error)
      })
  }

  const onClickOpenFullDocument = (idx): void => {
    refetch({
      includeSubscriptionDocument: true,
    })
      .then(() => {
        setDialogControlProps((prevState) => ({
          ...prevState,
          title: (
            <>
              <Typography variant="h1" gutterBottom>
                {subscribedProducts?.[idx]?.name} Contract
              </Typography>
            </>
          ),
          content: (
            <StoryblokRichText
              document={
                subscribedProducts?.[idx]?.storyblok?.content?.full_document
              }
            />
          ),
          actions: (
            <>
              <Button
                onClick={() => {
                  setDialogControlProps(dialogControlIntialState)
                }}
                autoFocus
                variant="contained"
                color="error"
                data-testid="confirm-dialog-btn-cancel"
              >
                Close
              </Button>
            </>
          ),
        }))
      })
      .catch((error) => {
        log.warn('Error while refetching:', error)
      })
  }

  const onClickCancelClaimRequest = (): void => {
    searchParams.set('filter', 'Unclaimed')
    navigate({
      pathname: routes.legalMatterListing.path,
      search: `?${searchParams.toString()}`,
    })
  }

  const onClickLoadMoreNotes = (endCursor: string): void => {
    void fetchMore({
      variables: {
        notesCursor: endCursor,
      },
    })
  }

  const onClickLoadMoreCalendarEvents = (endCursor: string): void => {
    void fetchMore({
      variables: {
        calendarCursor: endCursor,
      },
    })
  }

  const onClickAcceptReferredIn = (): void => {
    setDialogControlProps({
      ...dialogControlProps,
      open: true,
      title: (
        <>
          <Typography variant="h1" gutterBottom>
            Confirm Acceptance
          </Typography>
        </>
      ),
      content: (
        <>
          <Typography>
            You agree to accept this referred legal matter
          </Typography>
        </>
      ),
      actions: (
        <>
          <Button
            onClick={() => {
              setDialogControlProps(dialogControlIntialState)
            }}
            autoFocus
            variant="contained"
            color="error"
            data-testid="confirm-dialog-btn-cancel"
          >
            Cancel
          </Button>
          <Button
            onClick={() => {
              handleDialogActionConfirm('acceptReferrMatter')
            }}
            autoFocus
            variant="contained"
            color="primary"
            data-testid="confirm-dialog-btn-accept"
          >
            Confirm Acceptance
          </Button>
        </>
      ),
    })
  }

  const onClickRejectReferral = (): void => {
    setDialogControlProps({
      ...dialogControlProps,
      open: true,
      title: (
        <>
          <Typography variant="h1" gutterBottom>
            Confirm rejection of referred legal matter
          </Typography>
        </>
      ),
      content: (
        <>
          {dialogControlProps.errors?.message !== undefined &&
            dialogControlProps.errors?.message !== '' && (
              <Alert variant="outlined" severity="error">
                {dialogControlProps.errors.message}
              </Alert>
            )}
          <Typography>
            Please state why are you rejecting this legal matter:
          </Typography>
          <TextareaAutosize
            minRows={10}
            style={{ width: '97%' }}
            onChange={(e) => {
              setDialogControlProps((prevState) => ({
                ...prevState,
                textBoxContent: e.target.value,
              }))
            }}
          />
        </>
      ),
      actions: (
        <>
          <Button
            onClick={() => {
              setDialogControlProps(dialogControlIntialState)
            }}
            autoFocus
            variant="contained"
            color="error"
            data-testid="confirm-dialog-btn-cancel"
          >
            Cancel
          </Button>
          <Button
            onClick={() => {
              handleDialogActionConfirm('rejectReferrMatter')
            }}
            autoFocus
            variant="contained"
            color="primary"
            data-testid="confirm-dialog-btn-accept"
          >
            Confirm Rejection
          </Button>
        </>
      ),
    })
  }

  return (
    <>
      <Access
        requires={legalMatterModel.statusMatches(LegalMatterStatus.ReferredIn)}
        fallback={null}
      >
        <LegalMatterRequestAction
          requestMessage="This legal matter has been referred to you firm, please accept or reject this matter."
          textButtonOptionA="Accept Request"
          textButtonOptionB="Decline Request"
          onClickOptionAccept={onClickAcceptReferredIn}
          onClickOptionDecline={onClickRejectReferral}
        />
      </Access>
      <Access
        requiresAll={[
          legalMatterModel.acl.allows(
            user.aclIdentities,
            Permission.LegalMattersAccept,
          ),
          legalMatterModel.acl.allows(
            user.aclIdentities,
            Permission.LegalMattersReject,
          ),
          legalMatterModel.canBeAccepted(),
          legalMatterModel.canBeRejected(),
        ]}
        fallback={null}
      >
        <LegalMatterRequestAction
          textButtonOptionA="Accept Request"
          textButtonOptionB="Decline Request"
          onClickOptionAccept={onClickAcceptRequest}
          onClickOptionDecline={onClickDeclineRequest}
        />
      </Access>
      <Access requires={legalMatterModel.canBeClaimed()} fallback={null}>
        <LegalMatterRequestAction
          textButtonOptionA={canClaim ? 'Accept Legal Matter' : undefined}
          textButtonOptionB="Cancel"
          textButtonOptionC={canAssign ? 'Assign an Attorney' : undefined}
          onClickOptionAccept={onClickClaimRequest}
          onClickOptionDecline={onClickCancelClaimRequest}
          onClickOptionAssignLawyers={onClickAssignLawyer}
        />
      </Access>
      {!legalMatterModel.get('subscriber').subscriptionActive && (
        <Alert severity="warning" icon={false} sx={{ mb: 3 }} role="alert">
          <Typography variant="h5" gutterBottom>
            The member who created this legal matter does not have a valid
            subscription.
          </Typography>
        </Alert>
      )}
      <Box sx={{ width: '100%', typography: 'body1' }}>
        <TabContext value={selectedTab ?? defaultTab}>
          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <TabList
              onChange={handleTabChange}
              aria-label="lab API tabs example"
            >
              <Tab
                label={labelSlugs.LEGAL_MATTER_INFO_TAB}
                value="LEGALMATTER"
                disabled={isAssigning || isBeingClaimed}
              />
              <Tab
                label={labelSlugs.LEGAL_MATTER_INTAKE_TAB}
                value="INTAKEFORM"
              />
            </TabList>
          </Box>
          <TabPanel value="INTAKEFORM" sx={{ marginBottom: '60px' }}>
            <IntakeFormStep
              readonly={true}
              intakeDataRef={legalMatterModel.get('intakeDataRefs')?.[0]}
            ></IntakeFormStep>
          </TabPanel>
          <TabPanel value="LEGALMATTER">
            <ThreeColumnLayout>
              <LegalMatterSidebar
                legalMatterFragment={legalMatter}
                legalMatterStatusLabels={LawyerLegalMatterStatusLabels}
                textDocumentsRequestButton={
                  !legalMatterModel.isReadOnly() &&
                  legalMatterModel.acl.allows(
                    user.aclIdentities,
                    Permission.TasksCreate,
                  )
                    ? labelSlugs.REQUEST_ADDITIONAL_DOCUMENTS
                    : undefined
                }
                onClickDocumentRequestButton={() => {
                  setAddNewTaskMode(true)
                  selectAssigneeValues.current = [
                    {
                      label:
                        resolveSubscriberName(
                          legalMatterModel.get('subscriber')?.firstName,
                          legalMatterModel.get('subscriber')?.lastName,
                        ) ?? 'Client',
                      value: legalMatterModel.get('subscriber')?.key,
                    },
                  ]
                  taskFormDefaultProps.current = {
                    assignedTo: legalMatterModel.get('subscriber')?.key,
                    namePlaceholder: 'Request additional documents',
                    descriptionPlaceholder: 'List of documents to be requested',
                    textButton: 'Request',
                    textCancelButton: 'Cancel',
                  }
                }}
                onDocumentClick={handleDocumentDownload}
                onDocumentDelete={handleDocumentDelete}
                fileUploader={
                  !legalMatterModel.isReadOnly() ? (
                    <UploadWidget
                      attachButtonText={labelSlugs.SIDEBAR_UPLOAD_DOCUMENTS}
                      uploadHandler={uploadDocument}
                      handlerArgs={{
                        userKey: user.key,
                        legalMatterKey,
                      }}
                    />
                  ) : undefined
                }
                user={user}
              >
                <ClientContactInformation
                  subscriberName={resolveSubscriberName(
                    legalMatterModel.get('subscriber')?.firstName,
                    legalMatterModel.get('subscriber')?.lastName,
                  )}
                  productDetails={subscribedProducts}
                  phone={legalMatterModel.get('subscriber')?.phone ?? undefined}
                  email={legalMatterModel.get('subscriber')?.email ?? undefined}
                  onClickProductLink={handleSubscribedProductLinkClick}
                />
                <Divider sx={{ mt: 2 }} />
                <Access
                  requiresAll={[
                    legalMatterModel.acl.allows(
                      user.aclIdentities,
                      Permission.LegalMattersShare,
                    ),
                    legalMatterModel.canBeWithdrawn(),
                  ]}
                >
                  <Button
                    size="small"
                    variant="outlined"
                    onClick={handleShareLawyer}
                    sx={{ width: '100%', marginBottom: '10px' }}
                    data-testid="share-legal-matter-button"
                    aria-label="Share This Matter"
                  >
                    Share This Matter
                  </Button>
                </Access>
                <Access
                  requiresAny={[
                    legalMatterModel.acl.allows(
                      user.aclIdentities,
                      Permission.LegalMattersShareReject,
                    ),
                    legalMatterModel.acl.allows(
                      user.aclIdentities,
                      Permission.LegalMattersWithdraw,
                    ),
                  ]}
                >
                  <Button
                    size="small"
                    variant="outlined"
                    onClick={handleRemoveLawyer}
                    sx={{ width: '100%', marginBottom: '10px' }}
                    data-testid="withdraw-legal-matter-button"
                    aria-label="Remove Yourself From This Matter"
                  >
                    Remove Yourself From This Matter
                  </Button>
                </Access>
                <Access
                  requiresAll={[
                    legalMatterModel.acl.allows(
                      user.aclIdentities,
                      Permission.LegalMattersClose,
                    ) && legalMatterModel.canBeWithdrawn(),
                  ]}
                >
                  <Button
                    size="small"
                    variant="outlined"
                    onClick={handleCloseThisMatter}
                    sx={{ width: '100%', marginBottom: '10px' }}
                    data-testid="close-legal-matter-button"
                    aria-label="Close This Matter"
                  >
                    Close This Matter
                  </Button>
                </Access>
              </LegalMatterSidebar>
              <Box sx={{ minHeight: '1500px' }}>
                <Alert severity="info" icon={false} sx={{ mb: 3 }}>
                  <Typography variant="h5" gutterBottom>
                    Next steps:
                  </Typography>
                  <ul>
                    <li>Review the client intake information</li>
                    <li>Schedule a consultation with the client</li>
                    <li>
                      If you need additional documents from the client you can
                      create a task assigned to the client and request the
                      necessary documents.
                    </li>
                  </ul>
                </Alert>
                <Box>
                  <Typography variant="h2" gutterBottom>
                    Consultations
                  </Typography>
                  <Divider sx={{ mt: 2, mb: 1 }} />
                  <Grid container direction="row" alignItems="center">
                    <Grid
                      item
                      container
                      direction="row"
                      alignItems="center"
                      justifyContent="flex-start"
                      xs={7}
                      spacing={2}
                    >
                      <Grid item>Showing Events from:</Grid>
                      <Grid item>
                        <DropdownPeriodSelector
                          value={eventsPeriod.toString()}
                          onChangeValue={handleEventPeriodChange}
                        ></DropdownPeriodSelector>
                      </Grid>
                    </Grid>
                    <Grid item xs={5}>
                      {!addNewEventMode && (
                        <Box my={2} display="flex" justifyContent="flex-end">
                          <Button
                            variant="outlined"
                            color="primary"
                            data-testid="create-calendar-add-button"
                            disabled={
                              legalMatterModel.isReadOnly() ||
                              !legalMatterModel.acl.allows(
                                user.aclIdentities,
                                Permission.CalendarEventsCreate,
                              )
                            }
                            startIcon={<AddIcon />}
                            onClick={() => {
                              setAddNewEventMode(true)
                            }}
                          >
                            Schedule a Consultation
                          </Button>
                        </Box>
                      )}
                    </Grid>
                  </Grid>
                  <Divider sx={{ mt: 1, mb: 2 }} />
                  {addNewEventMode && (
                    <EventForm
                      namePlaceholder="Add a consultation topic"
                      serverError={addNewEventError}
                      textButton="Save"
                      textCancelButton="Cancel"
                      isSubmitting={eventLoading}
                      onChangeEventStartTime={handleChangeEventStartTime}
                      onChangeEventEndTime={handleChangeEventStartTime}
                      onClickButton={(data) => {
                        setAddNewEventError([])
                        if (
                          legalMatterModel.get('status') ===
                          LegalMatterStatus.Assigned
                        ) {
                          void createEvent({
                            variables: {
                              legalMatterKey,
                              name: data.eventName ?? '',
                              participants: [
                                legalMatter.subscriber.key,
                                user.key,
                              ],
                              description: data.eventDescription,
                              startTime: data.startTime,
                              endTime: data.endTime,
                            },
                            onCompleted: (data) => {
                              if (data.calendarEventCreate.success) {
                                const createdEvent =
                                  data?.calendarEventCreate as CalendarEventResponse
                                setLastCalendarEvent(
                                  createdEvent.calendarEvent.key,
                                )
                                setAddNewEventMode(false)
                              } else {
                                const errors =
                                  data?.calendarEventCreate as MutationError
                                setAddNewEventError([
                                  ...addNewEventError,
                                  errors.userErrors.length > 0
                                    ? errors.userErrors[0]?.fieldErrors[0]
                                        ?.errors[0]?.message
                                    : errors.message,
                                ])
                              }
                            },
                            onError: (error) => {
                              writeSentryError(
                                error,
                                {
                                  source: 'apollo_mutation',
                                  mutation_name: 'createEvent',
                                },
                                error.message,
                              )
                              enqueueSnackbar(
                                'There was a problem in creating the event, try again in a few seconds',
                                {
                                  variant: 'error',
                                },
                              )
                            },
                          })
                        } else {
                          enqueueSnackbar(
                            'Events cannot be created on this status of the Legal Matter',
                            { variant: 'error' },
                          )
                        }
                      }}
                      onClickCancelButton={handleCancelAddingEvent}
                    />
                  )}
                  {calendarEvents.length === 0 && (
                    <Box sx={{ mt: 2 }}>
                      <Alert severity="info" icon={false}>
                        <Typography variant="h5" gutterBottom>
                          No Scheduled Consultations
                        </Typography>
                        <Typography variant="body1" gutterBottom>
                          You can schedule consultations with the client by
                          clicking the "Schedule a Consultation" button above.
                        </Typography>
                      </Alert>
                    </Box>
                  )}
                  <AnimatedList animateItemKey={lastCalendarEvent}>
                    {calendarEvents.map((event) => (
                      <CalendarEventListItem
                        key={event.key}
                        calendarEvent={event}
                        isEditing={event.key in legalMatterCalendarEventsInEdit}
                        isSubmitting={eventLoading}
                        serverError={
                          legalMatterCalendarEventsInEdit[event.key]
                            ?.serverError
                        }
                        onChangeEventStartTime={
                          event.key in legalMatterCalendarEventsInEdit
                            ? (value) => {
                                handleChangeEventStartTime(value, event.key)
                              }
                            : undefined
                        }
                        onChangeEventEndTime={
                          event.key in legalMatterCalendarEventsInEdit
                            ? (value) => {
                                handleChangeEventEndTime(value, event.key)
                              }
                            : undefined
                        }
                        onClickMenu={
                          !legalMatterModel.isReadOnly()
                            ? (e) => {
                                handleEventDotMenuClick(e, event)
                              }
                            : undefined
                        }
                        onClickCancelEditButton={(key) => {
                          finishEventEditing(key ?? '')
                        }}
                        onClickSaveButton={(data) => {
                          eventInUpdateRef.current = data.eventKey ?? ''
                          void updateEvent({
                            variables: {
                              calendarEventKey: data.eventKey ?? '',
                              legalMatterKey,
                              participants: [
                                legalMatter.subscriber.key,
                                user.key,
                              ],
                              name: data.eventName,
                              description: data.eventDescription,
                              startTime: data.startTime,
                              endTime: data.endTime,
                            },
                            onCompleted: (data) => {
                              if (data.calendarEventUpdate.success) {
                                const mutatedEvent =
                                  data.calendarEventUpdate as CalendarEventResponse
                                finishEventEditing(
                                  mutatedEvent.calendarEvent.key,
                                )
                                enqueueSnackbar('Event updated successfully', {
                                  variant: 'success',
                                })
                              } else {
                                const errors =
                                  data?.calendarEventUpdate as MutationError
                                setLegalMatterCalendarEventsInEdit({
                                  ...legalMatterCalendarEventsInEdit,
                                  [eventInUpdateRef.current]: {
                                    serverError: [
                                      errors.userErrors.length > 0
                                        ? errors.userErrors[0]?.fieldErrors[0]
                                            ?.errors[0]?.message
                                        : errors.message,
                                    ],
                                  },
                                })
                              }
                            },
                            onError: () => {
                              enqueueSnackbar(
                                'There was a problem in updating the event, try again in a few seconds',
                                {
                                  variant: 'error',
                                },
                              )
                            },
                          })
                        }}
                      />
                    ))}
                    {legalMatter.calendarEvents.pageInfo.hasNextPage === true &&
                      legalMatter.calendarEvents.pageInfo.endCursor != null && (
                        <LoadingButton
                          loading={networkStatus === NetworkStatus.fetchMore}
                          disableElevation
                          variant="contained"
                          color="primary"
                          role="button"
                          aria-label="Load More"
                          onClick={() => {
                            onClickLoadMoreCalendarEvents(
                              legalMatter.calendarEvents.pageInfo.endCursor!,
                            )
                          }}
                          data-testid="events-load-more-button"
                        >
                          Load More
                        </LoadingButton>
                      )}
                  </AnimatedList>
                  <Divider sx={{ my: 2, mb: 1 }} />
                  <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                    {!addNewTaskMode && (
                      <Button
                        variant="outlined"
                        color="primary"
                        data-testid="create-task-add-button"
                        disabled={
                          legalMatterModel.isReadOnly() ||
                          !legalMatterModel.acl.allows(
                            user.aclIdentities,
                            Permission.TasksCreate,
                          )
                        }
                        onClick={() => {
                          setAddNewTaskMode(true)
                          selectAssigneeValues.current = [
                            {
                              label: 'Assign to me',
                              value: user.key,
                            },
                            {
                              label: 'Assign to the client',
                              value: legalMatter.subscriber?.key,
                            },
                          ]
                        }}
                        startIcon={<AddIcon />}
                      >
                        Add Task
                      </Button>
                    )}
                  </Box>
                  <Divider sx={{ mt: 1, mb: 2 }} />
                </Box>
                <Box mt={1}>
                  {addNewTaskMode && (
                    <TaskForm
                      namePlaceholder={
                        taskFormDefaultProps.current.namePlaceholder
                      }
                      descriptionPlaceholder={
                        taskFormDefaultProps.current.descriptionPlaceholder
                      }
                      textButton={taskFormDefaultProps.current.textButton}
                      textCancelButton={
                        taskFormDefaultProps.current.textCancelButton
                      }
                      selectAssigneeProps={selectAssigneeValues.current}
                      assignedTo={taskFormDefaultProps.current.assignedTo}
                      serverError={addNewTaskError}
                      onClickButton={(data) => {
                        taskFormDefaultProps.current = {
                          namePlaceholder: 'Enter task name',
                          descriptionPlaceholder: 'Enter task description',
                          textButton: 'Save',
                          textCancelButton: 'Cancel',
                          assignedTo: null,
                        }
                        if (
                          legalMatterModel.get('status') ===
                          LegalMatterStatus.Assigned
                        ) {
                          void createTask({
                            variables: {
                              legalMatterKey,
                              name: data.name ?? '',
                              description: data.description ?? '',
                              assignedToKey: data.assignedTo,
                            },
                            onCompleted: (data) => {
                              if (data.taskCreate.success) {
                                setAddNewTaskMode(false)
                                enqueueSnackbar('Task created successfully', {
                                  variant: 'success',
                                })
                              } else {
                                const errors = data?.taskCreate as MutationError
                                setAddNewTaskError([
                                  ...addNewTaskError,
                                  errors?.userErrors?.length > 0
                                    ? errors.userErrors[0]?.fieldErrors[0]
                                        ?.errors[0]?.message
                                    : errors.message,
                                ])
                              }
                            },
                            onError: () => {
                              enqueueSnackbar(
                                'There was a problem in creating the task, try again in a few seconds',
                                {
                                  variant: 'error',
                                },
                              )
                            },
                          })
                        } else {
                          enqueueSnackbar(
                            'Tasks cannot be created on this status of the Legal Matter',
                            { variant: 'error' },
                          )
                        }
                      }}
                      isSubmitting={taskLoading}
                      onClickCancelButton={handleCancelAddingTask}
                    />
                  )}
                  <LawyerTaskListing
                    legalMatterKey={legalMatterKey}
                    isReadOnly={legalMatterModel.isReadOnly()}
                    handleDocumentDownload={handleDocumentDownload}
                    handleDocumentDelete={handleDocumentDelete}
                    handleDocumentUpload={uploadDocument}
                    selectAssigneeOptions={[
                      {
                        label: 'Assign to me',
                        value: user.key,
                      },
                      {
                        label: 'Assign to the client',
                        value: legalMatter.subscriber?.key,
                      },
                    ]}
                  />
                </Box>
              </Box>
              <Box>
                <Access requires={canCreateNotes} fallback={null}>
                  <LawyerNotesForm
                    onSubmit={handleSubmitNoteData}
                    noteMaxLenght={1000}
                    isReadOnly={!canCreateNotes}
                  />
                </Access>
                <LawyerNotes
                  currentUser={user}
                  fragment={legalMatter}
                  disableMenu={!canCreateNotes}
                  fetchMoreLoading={networkStatus === NetworkStatus.fetchMore}
                  onLoadMore={onClickLoadMoreNotes}
                />
              </Box>
            </ThreeColumnLayout>
          </TabPanel>
        </TabContext>
      </Box>
      <Box style={{ display: 'none' }}>
        <a ref={downloadRef} style={{ display: 'none' }} target="_blank" />
      </Box>
      <DotMenu
        anchor={dotMenuAnchor}
        open={Boolean(dotMenuAnchor)}
        onItemSelected={(action) => {
          setDotMenuAnchor(null)
          handleTaskDotMenuSelection(action)
        }}
        onClose={() => {
          setDotMenuAnchor(null)
        }}
      />
      <ConfirmationDialog
        open={dialogControlProps.open}
        onClose={() => {
          setDialogControlProps(dialogControlIntialState)
        }}
        title={dialogControlProps.title}
        content={dialogControlProps.content}
        actions={dialogControlProps.actions}
        errorMessage={dialogControlProps?.errors?.message}
      />
    </>
  )
}

export default LawyerLegalMatter
