/* eslint-disable @typescript-eslint/no-empty-function */
import {
  DeleteDocumentMutation,
  SignedUrlCreateMutation,
  UrlSignMutationResponse,
} from '../@types/generated/graphql'
import { gql } from '../@types/generated/gql'
import {
  ApolloCache,
  DefaultContext,
  FetchResult,
  MutationFunctionOptions,
  OperationVariables,
  useMutation,
} from '@apollo/client'
import { IDownload, IUpload, UploadStatus } from '../@types/Upload'
import config from 'config'
import log from 'loglevel'
import { nanoid } from 'nanoid'
import { decodeKey } from './KeyFormatter'

const deleteDocumentMutation = gql(`
  mutation deleteDocument($documentKey: Key!) {
    legalMatterDocumentDelete(documentKey: $documentKey) {
      ... on DeleteResponse {
        code
        success
        message
        objectRef
      }
    }
  }
`)

const documentSignedUrlCreateMutation = gql(`
  mutation SignedUrlCreate($input: UrlSignMutationInput!) {
    signedUrlCreate(input: $input) {
      ... on UrlSignMutationResponse {
        code
        success
        message
        url
      }
      ... on MutationError {
        code
        success
        message
        userErrors {
          code
          fieldErrors {
            name
            errors {
              code
              message
            }
          }
        }
      }
    }
  }
`)

type GetUploadUrl = ({
  basePath,
  metadata,
  file,
}: {
  basePath: string
  metadata: { key: string; value: string }[]
  file: File
}) => Promise<string>

type GetDownloadUrl = ({ filePath }: { filePath: string }) => Promise<string>

const useSignedUrls = (): {
  getDownloadUrl: GetDownloadUrl
  getUploadUrl: GetUploadUrl
} => {
  const [documentSignedUrl] = useMutation<SignedUrlCreateMutation>(
    documentSignedUrlCreateMutation,
  )

  const getUploadUrl: GetUploadUrl = ({ basePath, metadata, file }) => {
    return new Promise((resolve, reject) => {
      void documentSignedUrl({
        variables: {
          input: {
            filePath: `${basePath}/${nanoid()}`.replace(/^\/+/, ''),
            contentType: file.type,
            contentSize: file.size,
            operation: 'PUT',
            metadata,
          },
        },
        onCompleted: (data) => {
          const response = data.signedUrlCreate as UrlSignMutationResponse
          if (response?.url != null) {
            return resolve(response.url)
          } else {
            return reject(new Error(data.signedUrlCreate.message))
          }
        },
        onError: (e) => {
          return reject(e)
        },
      })
    })
  }

  const getDownloadUrl: GetDownloadUrl = ({ filePath }) => {
    return new Promise((resolve, reject) => {
      void documentSignedUrl({
        variables: {
          input: {
            filePath: filePath,
            operation: 'GET',
            metadata: [],
          },
        },
        onCompleted: (data) => {
          const response = data.signedUrlCreate as UrlSignMutationResponse
          if (response?.url != null) {
            return resolve(response.url)
          } else {
            return reject(new Error(data.signedUrlCreate.message))
          }
        },
        onError: (e) => {
          return reject(
            e?.message
              ? new Error(e?.message)
              : new Error(
                  'There was an error while downloading your file, try again in a few seconds',
                ),
          )
        },
      })
    })
  }

  return {
    getDownloadUrl,
    getUploadUrl,
  }
}

const uploadHttpRequest = ({
  uploadUrl,
  file,
  metadata,
  onComplete,
  onError,
  onProgress,
}: {
  uploadUrl: string
  file: File
  metadata: { key: string; value: string }[]
  onComplete: (file: File) => void
  onError: (error: Error) => void
  onProgress: (status: { progress: number }) => void
}) => {
  try {
    log.info('uploadHttpRequest', uploadUrl, file, metadata)
    const xhr = new XMLHttpRequest()
    xhr.open('PUT', uploadUrl, true)
    metadata.forEach(({ key, value }) => {
      xhr.setRequestHeader(key, value)
    })

    // Monitor important upload events
    xhr.upload.onprogress = (event) => {
      log.debug('onprogress', event.lengthComputable, event.loaded)

      if (event.lengthComputable) {
        onProgress({
          progress: Math.round((event.loaded / event.total) * 100),
        })
      }
    }

    // Handle upload completion
    xhr.onload = () => {
      if (xhr.status === 200) {
        onComplete(file)
      } else {
        onError(new Error(`Upload failed: ${xhr.statusText}`))
      }
    }

    // Handle upload errors
    xhr.onerror = (event) => {
      log.error('xhr.onerror', event)
      onError(new Error('An error occurred during file upload'))
    }

    // Start the upload
    xhr.send(file)
  } catch (err) {
    log.error('xhr.catch', err)
    onError(new Error('An error occurred during file upload'))
  }
}

interface DocumentsInterface {
  deleteDocument: (
    mutationOpts:
      | MutationFunctionOptions<
          DeleteDocumentMutation,
          OperationVariables,
          DefaultContext,
          ApolloCache<any>
        >
      | undefined,
  ) => Promise<FetchResult<DeleteDocumentMutation>>
  downloadDocument: (filePath: string) => IDownload
  uploadDocument: (props: {
    userKey: string
    legalMatterKey: string
    file: File
    taskId?: string | null
  }) => IUpload
}
const useDocuments = (): DocumentsInterface => {
  const gclouStorage = useSignedUrls()

  const [deleteDocument] = useMutation<DeleteDocumentMutation>(
    deleteDocumentMutation,
  )

  const downloadDocument = (filePath: string): IDownload => {
    let onCompleteCallback: ({
      fileName,
      objectUrl,
    }: {
      fileName: string
      objectUrl: string
    }) => Promise<void> = () => {
      return Promise.resolve()
    }

    let onErrorCallback: ({
      filePath,
      error,
    }: {
      filePath: string
      error: Error
    }) => void = () => {}

    gclouStorage
      .getDownloadUrl({ filePath })
      .then(async (fetchUrl) => {
        // Fetch the actual file as a blob
        const contentRes = await fetch(fetchUrl, { method: 'GET' })
        log.info('Downloaded file', contentRes.headers, contentRes)
        const fileName = contentRes.headers.get('X-Goog-Meta-File_name')
        const objectUrl = URL.createObjectURL(await contentRes.blob())
        await onCompleteCallback({
          fileName: fileName ? decodeURI(fileName) : 'LegalMatterDocument',
          objectUrl,
        })
        URL.revokeObjectURL(objectUrl)
      })
      .catch((err) => {
        log.error('Error downloading file', err)
        onErrorCallback({
          filePath,
          error: new Error('Error downloading file'),
        })
      })

    return {
      onComplete(
        callback: ({
          fileName,
          objectUrl,
        }: {
          fileName: string
          objectUrl: string
        }) => Promise<void>,
      ): void {
        onCompleteCallback = callback
      },
      onError(
        callback: ({
          filePath,
          error,
        }: {
          filePath: string
          error: Error
        }) => void,
      ): void {
        onErrorCallback = callback
      },
    }
  }

  const uploadDocument = (props: {
    userKey: string
    legalMatterKey: string
    file: File
    taskKey?: string | null
  }): IUpload => {
    let completed = false
    const onCompleteCallbacks: ((status: UploadStatus) => void)[] = []
    const onErrorCallbacks: ((status: UploadStatus) => void)[] = []
    const onProgressCallbacks: ((status: UploadStatus) => void)[] = []

    const allowedExtensions = new RegExp(
      config.fileUploadConfig.allowedFileTypes,
      'i',
    )
    if (!allowedExtensions.exec(props.file.name)) {
      throw new Error('File type not allowed')
    }

    if (props.file.size > config.fileUploadConfig.maxFileSize) {
      throw new Error('File too big')
    }

    // Signed urls are case sensitive for headers
    const metadata = [
      {
        key: 'Content-Type',
        value: props.file.type,
      },
      {
        key: 'X-Goog-Meta-Created_by',
        value: props.userKey,
      },
      {
        key: 'X-Goog-Meta-File_name',
        value: encodeURI(props.file.name),
      },
    ]
    if (props.taskKey != null) {
      metadata.push({
        key: 'X-Goog-Meta-Task_id',
        value: props.taskKey,
      })
    }

    gclouStorage
      .getUploadUrl({
        basePath: `${config.legalMatterDocumentsBasePath}/${decodeKey(props.legalMatterKey).id}`,
        metadata,
        file: props.file,
      })
      .then((url) => {
        uploadHttpRequest({
          uploadUrl: url,
          file: props.file,
          metadata,
          onComplete: () => {
            completed = true
            onCompleteCallbacks.forEach((cb) =>
              cb({
                completed: true,
                progress: 100,
                error: null,
              }),
            )
          },
          onError: (error) => {
            onErrorCallbacks.forEach((cb) =>
              cb({
                completed: true,
                progress: 0,
                error: error.message,
              }),
            )
          },
          onProgress: ({ progress }) => {
            onProgressCallbacks.forEach((cb) =>
              cb({
                completed: false,
                progress,
                error: null,
              }),
            )
          },
        })
      })
      .catch((error) => {
        log.error('Error getting upload URL', error)
        onErrorCallbacks.forEach((cb) =>
          cb({
            completed: false,
            progress: 0,
            error: error.message,
          }),
        )
      })

    return {
      file: props.file,
      completed,
      onComplete(callback: (status: UploadStatus) => void) {
        onCompleteCallbacks.push(callback)
      },
      onError(callback: (status: UploadStatus) => void) {
        onErrorCallbacks.push(callback)
      },
      onProgress(callback: (status: UploadStatus) => void) {
        onProgressCallbacks.push(callback)
      },
    }
  }

  return {
    deleteDocument,
    downloadDocument,
    uploadDocument,
  }
}

export default useDocuments
