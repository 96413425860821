import { useContext } from 'react'
import models from 'models'
import { UserProfileContext } from 'view/providers/UserProfileProvider'
import LawyerLegalMatterListing from './LawyerLegalMatterListing'
import SubscriberLegalMatterListing from './SubscriberLegalMatterListing'

const LegalMatterListing = (): JSX.Element | null => {
  const { user } = useContext(UserProfileContext)

  if (user instanceof models.FirmUser || user instanceof models.BaseUser) {
    return (
      <>
        <LawyerLegalMatterListing></LawyerLegalMatterListing>
      </>
    )
  }

  return (
    <>
      <SubscriberLegalMatterListing></SubscriberLegalMatterListing>
    </>
  )
}

export default LegalMatterListing
