import { type AttachedFile } from './Upload'
import { type TaskChargeType } from './generated/graphql'

export enum AlertContent {
  REJECTED = 'You rejected the legal matter successfully.',
  WITHDRAWN = 'You removed yourself successfully',
}

export enum DialogType {
  DEFAULT = 'DEFAULT',
  REJECT = 'REJECT',
  CHARGETASK = 'CHARGETASK',
  FILES = 'FILES',
  DELETETASK = 'DELETETASK',
}

interface Labels {
  title?: string
  subtitle?: string
  textContent?: string
}

export interface DialogActions {
  itemKey?: string
  action?: string
  confirmLabel?: string
  cancelLabel?: string
  textBoxContent?: string
  confirmationCheckBox?: boolean
}

interface DialogTask {
  taskKey?: string
  taskName?: string
  chargeType?: TaskChargeType
  chargeAmount?: number
  chargeReason?: string
}

export interface DialogFiles {
  files?: AttachedFile[]
  taskId?: string
}

export interface Errors {
  message?: string | undefined
}

export interface DialogControlType {
  open: boolean
  dialogType: DialogType
  labels?: Labels
  actions?: DialogActions
  task?: DialogTask
  files?: DialogFiles
  errors?: Errors
}
