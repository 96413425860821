import Button from '@mui/material/Button'
import Grid from '@mui/material/Grid'
import { labelSlugs } from 'constants/index'
import FilesList from './FilesList'
import { type Document } from '../../../../src/@types/generated/graphql'
import User from 'models/User'

interface Props {
  onClickToDocument?: (documentPath: string) => void
  onClickDocumentRequestButton?: () => void
  onDocumentDelete?: (documentKey: string) => void
  documents: Pick<Document, 'key' | 'name' | 'fullPath'>[] | undefined
  textDocumentsRequestButton?: string
  fileUploader?: JSX.Element
  user: User
}
const SidebarDocuments = ({
  documents,
  user,
  onClickToDocument,
  fileUploader,
  onDocumentDelete,
  textDocumentsRequestButton,
  onClickDocumentRequestButton,
}: Props): JSX.Element => {
  return (
    <>
      <Grid mt={1} pr={2} container data-testid="sidebar-documents">
        {/* List of uploaded documents */}
        {documents !== undefined ? (
          <FilesList
            files={documents}
            filesAttachedLabel={labelSlugs.DOCUMENTS_UPLOADED}
            onFileClick={onClickToDocument}
            onRemoveFile={(key) => {
              onDocumentDelete?.(key)
            }}
            user={user}
          />
        ) : null}
        {fileUploader !== undefined ? (
          <Grid item pr={2} xs={12}>
            {fileUploader}
          </Grid>
        ) : null}
        {textDocumentsRequestButton !== undefined ? (
          <Grid item xs={12} mt={2}>
            <Button
              size="small"
              variant="outlined"
              color="primary"
              data-testid="request-documents-button"
              onClick={onClickDocumentRequestButton}
              sx={{ width: '100%' }}
            >
              {textDocumentsRequestButton}
            </Button>
          </Grid>
        ) : null}
      </Grid>
    </>
  )
}

export default SidebarDocuments
