import { useState } from 'react'
import { NetworkStatus, useQuery } from '@apollo/client'
import { omit } from 'lodash'
import { enqueueSnackbar } from 'notistack'
import Alert from '@mui/material/Alert'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import {
  CursorDirection,
  LegalMatterTasksDocument,
  Permission,
  TaskStatus,
} from '../../../@types/generated/graphql'
import type { IUpload } from '../../../@types/Upload'
import TaskListItem from 'view/components/organisms/TaskListItem'
import ConfirmationDialog, {
  type ConfirmationDialogProps,
} from '../molecules/ConfirmationDialog'
import ChargeforTaskInfo from 'view/components/molecules/ChargeforTaskInfo'
import AnimatedList from 'view/components/molecules/AnimatedList'
import useTasks from 'utils/useTasks'
import useCurrentUser from 'utils/useCurrentUser'
import { getPastDate } from 'utils/dateUtils'
import { paginationLimits } from 'config'
import TaskLoader from '../molecules/TaskLoader'
import { makeEmptyData } from 'utils/useApolloClient'
import TaskFilter from '../organisms/TaskFilters'
import AclEntity from 'models/AclEntity'
import LoadingButton from '@mui/lab/LoadingButton'
import UploadWidget from '../molecules/UploadWidget'

interface TaskFilters {
  daysSinceUpdated: number
  tasksLastUpdatedSince?: Date
  tasksCompletedSince?: Date
  tasksAssignedToIn?: string[]
  tasksAssignedToNotIn?: string[]
  tasksStatus?: TaskStatus
}

interface TaskModuleProps {
  legalMatterKey: string | undefined
  isReadOnly: boolean
  handleDocumentDownload?: (documetPath: string) => void
  handleDocumentDelete?: (documentKey: string) => void
  handleDocumentUpload?: (props: {
    userKey: string
    legalMatterKey: string
    file: File
    taskKey?: string | null
  }) => IUpload
}

const dialogConfigInitialState: ConfirmationDialogProps = {
  title: <></>,
  content: <></>,
  actions: <></>,
  open: false,
}

const defaultPastDays = 7

const SubscriberTaskListing = ({
  legalMatterKey,
  isReadOnly,
  handleDocumentDownload,
  handleDocumentDelete,
  handleDocumentUpload,
}: TaskModuleProps): JSX.Element => {
  const { user, loading: userLoading } = useCurrentUser()
  const [noTasksMessage, setNoTasksMessage] = useState<string>(
    'There are no tasks assigned to you.',
  )
  const [filters, setFilters] = useState<TaskFilters>({
    daysSinceUpdated: defaultPastDays,
  })
  const [dialogConfig, setDialogConfig] = useState<ConfirmationDialogProps>(
    dialogConfigInitialState,
  )
  const { updateTaskStatus, taskLoading } = useTasks(legalMatterKey)

  const { data, networkStatus, refetch, fetchMore } = useQuery(
    LegalMatterTasksDocument,
    {
      notifyOnNetworkStatusChange: true,
      skip: userLoading || legalMatterKey == null,
      variables: {
        legalMatterKey,
        tasksLimit: paginationLimits.tasks,
        tasksAssignedToIn: [user.key],
        tasksCursorDirection: CursorDirection.Next,
        tasksLastUpdatedSince: getPastDate(defaultPastDays, 'UTC'),
      },
    },
  )

  const legalMatter = data?.legalMatters?.edges[0]?.node
  const tasks = legalMatter?.tasks?.edges?.map((edge) => edge.node) ?? []
  const pageInfo = legalMatter?.tasks?.pageInfo ?? makeEmptyData().pageInfo

  const onClickDone = (key): void => {
    void updateTaskStatus({
      variables: {
        taskKey: key,
        status: TaskStatus.Completed,
      },
      onCompleted: (data) => {
        if (data.taskStatusUpdate.success)
          enqueueSnackbar('Task updated', {
            variant: 'success',
          })
      },
      onError: () => {
        enqueueSnackbar(
          'There was an error while updating the task, try again in a few seconds',
          {
            variant: 'error',
          },
        )
      },
    })
  }

  const handleChangeModeInfoClick = (key: string): void => {
    const task = tasks.find((task) => task.key === key)
    setDialogConfig((prevState) => ({
      ...prevState,
      open: true,
      fullWidth: true,
      content: (
        <ChargeforTaskInfo
          taskName={task?.name ?? ''}
          chargeType={task?.chargeType !== null ? task?.chargeType : undefined}
          chargeReason={task?.chargeReason ?? ''}
          chargeAmount={task?.chargeAmount ?? 0}
        />
      ),
    }))
  }

  return (
    <>
      <TaskFilter
        daysSinceUpdated={filters.daysSinceUpdated}
        tabs={{
          'my-tasks': 'My Tasks',
          'attorney-tasks': 'Attorney Tasks',
        }}
        onChangeFilters={(newFilters) => {
          const targetDate = getPastDate(newFilters.daysSinceUpdated, 'UTC')
          const taskFilters: TaskFilters = {
            ...filters,
            tasksAssignedToIn: undefined,
            tasksAssignedToNotIn: undefined,
            daysSinceUpdated: newFilters.daysSinceUpdated,
            tasksLastUpdatedSince:
              targetDate instanceof Date ? targetDate : undefined,
          }
          switch (newFilters.context) {
            case 'my-tasks':
              if (user?.key != null) {
                taskFilters.tasksAssignedToIn = [user.key]
              }
              setNoTasksMessage('There are no tasks assigned to you.')
              break
            default:
              taskFilters.tasksAssignedToNotIn = [user.key!]
              setNoTasksMessage(
                'There are no tasks assigned to other attorneys.',
              )
              break
          }

          setFilters({ ...taskFilters })
          const { daysSinceUpdated, ...queryParams } = taskFilters
          refetch({ ...queryParams })
        }}
      />
      <Box data-testid="task-list">
        <TaskLoader
          loading={
            userLoading ||
            [NetworkStatus.loading, NetworkStatus.setVariables].includes(
              networkStatus,
            )
          }
        >
          <AnimatedList animateItemKey={undefined}>
            {tasks.length > 0 ? (
              tasks.map((task) => {
                const taskAcl = new AclEntity(task.acl)
                return (
                  <TaskListItem
                    isUpdating={taskLoading}
                    user={user}
                    key={task.key}
                    task={omit(task, 'trackedMinutes')}
                    buttonText="Mark as Done"
                    onClickDone={
                      !isReadOnly &&
                      taskAcl.allows(
                        user.aclIdentities,
                        Permission.TasksUpdateStatus,
                      )
                        ? onClickDone
                        : undefined
                    }
                    onPlanInfoClick={handleChangeModeInfoClick}
                    attachedFiles={task.documents}
                    onFileClick={handleDocumentDownload}
                    onRemoveSelectedFile={handleDocumentDelete}
                    fileUploader={
                      handleDocumentUpload &&
                      !isReadOnly &&
                      taskAcl.allows(
                        user.aclIdentities,
                        Permission.TasksUploadDocuments,
                      ) ? (
                        <UploadWidget
                          uploadHandler={handleDocumentUpload}
                          handlerArgs={{
                            userKey: user.key,
                            legalMatterKey,
                            taskKey: task.key,
                          }}
                        />
                      ) : undefined
                    }
                    disableMenu={true}
                  />
                )
              })
            ) : (
              <Box sx={{ mt: 2 }}>
                <Alert severity="info" icon={false}>
                  <Typography variant="h5" gutterBottom>
                    {noTasksMessage}
                  </Typography>
                  <Typography variant="body1" gutterBottom>
                    Your attorney can create a tasks for you to upload documents
                    or complete additional information to address the legal
                    matter.
                  </Typography>
                </Alert>
              </Box>
            )}
          </AnimatedList>
          {pageInfo.hasNextPage != null && pageInfo.endCursor != null ? (
            <Box mb={2} display="flex" justifyContent="flex-end">
              <LoadingButton
                loading={networkStatus === NetworkStatus.fetchMore}
                disableElevation
                variant="contained"
                color="primary"
                role="button"
                aria-label="Load More"
                onClick={() => {
                  if (pageInfo.endCursor != null) {
                    void fetchMore({
                      variables: {
                        tasksCursorDirection: CursorDirection.Next,
                        tasksCursor: pageInfo.endCursor,
                      },
                    })
                  }
                }}
                disabled={pageInfo.hasNextPage === false}
                data-testid="loading-button"
              >
                Load More
              </LoadingButton>
            </Box>
          ) : null}
        </TaskLoader>
      </Box>
      <ConfirmationDialog
        open={dialogConfig.open}
        onClose={() => {
          setDialogConfig(dialogConfigInitialState)
        }}
        title={dialogConfig.title}
        content={dialogConfig.content}
        actions={dialogConfig.actions}
        errorMessage={dialogConfig?.errorMessage}
      />
    </>
  )
}

export default SubscriberTaskListing
