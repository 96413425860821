import { truncate } from 'lodash'
import { styled } from '@mui/material/styles'
import AttachmentIcon from '@mui/icons-material/Attachment'
import Grid from '@mui/material/Grid'
import Button from '@mui/material/Button'
import DeleteIcon from '@mui/icons-material/Delete'
import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip'

const LightTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.primary.light,
    color: 'white',
    boxShadow: theme.shadows[1],
    borderRadius: 4,
    fontSize: 11,
  },
}))

const FileItem = ({
  name,
  truncateLength = 30,
  onClick = undefined,
  onRemove = undefined,
}: {
  name: string
  truncateLength?: number
  onClick?: React.MouseEventHandler<HTMLSpanElement>
  onRemove?: React.MouseEventHandler<HTMLSpanElement>
}) => {
  const displayName =
    name.length >= truncateLength
      ? truncate(name, { length: truncateLength })
      : name
  const fileButton = (
    <Button
      onClick={onClick ?? undefined}
      startIcon={<AttachmentIcon />}
      sx={{ textTransform: 'none' }}
    >
      {displayName}
    </Button>
  )

  return (
    <Grid
      container
      alignItems="center"
      justifyContent="flex-start"
      data-testid="filelist-attached-files"
    >
      <Grid item xs="auto">
        {name.length >= truncateLength ? (
          <LightTooltip title={name} placement="right-start">
            {fileButton}
          </LightTooltip>
        ) : (
          fileButton
        )}
      </Grid>
      <Grid item xs>
        {onRemove != null ? (
          <Button
            onClick={onRemove}
            data-testid="filelist-remove-file-btn"
            aria-label="Delete file"
          >
            <DeleteIcon color="secondary" />
          </Button>
        ) : (
          ' '
        )}
      </Grid>
    </Grid>
  )
}

export default FileItem
