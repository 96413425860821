import { useEffect, useState } from 'react'
import { collection, onSnapshot, query } from 'firebase/firestore'
import { firestoreDb } from '../firebaseConfig'
import log from 'loglevel'

interface globalConfig {
  isMaintainanceMode: boolean
  message?: string
}

const maintainanceModeInitState: globalConfig = {
  isMaintainanceMode: false,
  message:
    'Our site is under maintenance ... please come back in a few minutes.',
}

export const useLegalPlansConfig = () => {
  const [maintainanceMode, setMaintainanceMode] = useState<globalConfig>(
    maintainanceModeInitState,
  )

  const fbQuery = query(collection(firestoreDb, 'legalPlansConfig'))
  useEffect(() => {
    const unsubscribeFromFirestoreLegalPlansConfig = onSnapshot(
      fbQuery,
      (snapshot) => {
        snapshot.docs.forEach((doc) => {
          setMaintainanceMode({
            isMaintainanceMode: doc.data().maintenanceMode,
            message: doc.data().banner ?? maintainanceModeInitState.message,
          })
        })
      },
    )
    return () => unsubscribeFromFirestoreLegalPlansConfig()
  }, [])

  return maintainanceMode
}
