import log, { type LogLevelDesc } from 'loglevel'
import { ApolloProvider } from '@apollo/client'
import { RouterProvider } from 'react-router-dom'
import { SnackbarProvider } from 'notistack'
import config from 'config'
import router from 'routes'
import { ThemeRegistry } from 'view/providers/ThemeRegistry'
import UserProfileProvider from 'view/providers/UserProfileProvider'
import SentryScopeProvider from './providers/SentryScopeProvider'
import EventRegistryProvider from './providers/EventProvider'
import { useApolloClient } from 'utils/useApolloClient'
import { useLegalPlansConfig } from 'utils/useLegalPlansConfig'
import MaintenancePage from './components/pages/MaintenancePage'

log.warn(`version: ${config.version}`)
log.setLevel(config.logLevel as LogLevelDesc)

const App = (): JSX.Element => {
  const apolloClient = useApolloClient()
  const { isMaintainanceMode, message } = useLegalPlansConfig()

  if (isMaintainanceMode) {
    return <MaintenancePage message={message} />
  }

  return (
    <ThemeRegistry>
      <ApolloProvider client={apolloClient}>
        <UserProfileProvider>
          <EventRegistryProvider>
            <SentryScopeProvider>
              <SnackbarProvider maxSnack={5}>
                <RouterProvider router={router} />
              </SnackbarProvider>
            </SentryScopeProvider>
          </EventRegistryProvider>
        </UserProfileProvider>
      </ApolloProvider>
    </ThemeRegistry>
  )
}

export default App
