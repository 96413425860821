import { useEffect, useRef, useState } from 'react'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Grid from '@mui/material/Grid'
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline'
import FeedbackIcon from '@mui/icons-material/Feedback'
import LinearProgress from '@mui/material/LinearProgress'
import Typography from '@mui/material/Typography'
import BackupIcon from '@mui/icons-material/BackupTwoTone'
import { IUpload, UploadStatus } from '../../../@types/Upload'
import FileItem from '../atoms/FileItem'

interface ItemUploadProps {
  uploadJob: IUpload
}

export const UploadIndicator = ({
  uploadJob,
}: ItemUploadProps): JSX.Element => {
  const [uploadCompleted, setUploadCompleted] = useState(false)
  const [progress, setProgress] = useState(0)
  const [error, setError] = useState<string | null>(null)

  useEffect(() => {
    uploadJob.onProgress((upload: UploadStatus): void => {
      setProgress(upload.progress)
      setUploadCompleted(upload.completed)
    })

    uploadJob.onError((upload: UploadStatus) => {
      setError(upload.error)
      setUploadCompleted(upload.completed)
    })

    uploadJob.onComplete((upload: UploadStatus) => {
      setUploadCompleted(upload.completed)
    })
  }, [])

  return (
    <Box>
      <Grid container alignItems="center" justifyContent="flex-start">
        <Grid item xs="auto">
          {uploadJob.file?.name ? (
            <FileItem name={uploadJob.file.name} truncateLength={35} />
          ) : null}
        </Grid>
        <Grid item xs={1}>
          {uploadCompleted && !error ? (
            <CheckCircleOutlineIcon color="success" style={{ marginLeft: 8 }} />
          ) : null}
          {error ? (
            <FeedbackIcon
              color="error"
              style={{ marginLeft: 8, color: 'rgb(211, 47, 47)' }}
            />
          ) : null}
        </Grid>
      </Grid>

      {!uploadCompleted ? (
        <LinearProgress variant="determinate" value={progress} />
      ) : null}
      {error ? <Typography color="error">{error}</Typography> : null}
    </Box>
  )
}

interface Props {
  uploadHandler: (args: any) => IUpload
  handlerArgs?: any
  attachButtonText?: string
}
const UploadWidget = ({
  uploadHandler,
  handlerArgs = {},
  attachButtonText = 'Attach Files',
}: Props): JSX.Element => {
  const inputFile = useRef<HTMLInputElement>(null)
  const [uploadJobs, setUploadJobs] = useState<IUpload[]>([])

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files.length > 0) {
      const jobs = Array.from(event.target.files).map((file) =>
        uploadHandler({ file, ...handlerArgs }),
      )

      setUploadJobs((prevJobs) => [...prevJobs, ...jobs])
      // // Ensure we remove the indicator after the upload is completed
      jobs.forEach((job) => {
        job.onComplete(() => {
          setTimeout(() => {
            setUploadJobs((prevJobs) =>
              prevJobs.filter((j) => j.file.name !== job.file.name),
            )
          }, 5000)
        })
      })
    }
  }

  const handleAttachButtonClick = (): void => {
    inputFile?.current?.click()
  }

  return (
    <Grid container direction={'row'} aria-labelledby="fileuploader-attach-btn">
      <Grid item xs={12}>
        {uploadJobs.map((job) => (
          <UploadIndicator
            key={`${job.file.name}${job.file.size}`}
            uploadJob={job}
          />
        ))}
        <Button
          id="fileuploader-attach-btn"
          data-testid="fileuploader-attach-btn"
          variant="text"
          role="button"
          aria-label="Attach File Button"
          startIcon={<BackupIcon color="secondary" />}
          style={{ textTransform: 'none' }}
          onClick={handleAttachButtonClick}
        >
          {attachButtonText}
        </Button>
      </Grid>
      <input
        type="file"
        id="file"
        style={{ display: 'none' }}
        ref={inputFile}
        onChange={handleFileChange}
        data-testid="fileuploader-file-input"
        aria-label="File Uploader Input"
        multiple
      />
    </Grid>
  )
}

export default UploadWidget
